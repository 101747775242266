<template>
  <ul class="header-navigation">
    <header-navigation-item
      v-for="(item, index) in menuItems"
      :key="index"
      :menu-item="item"
    />
  </ul>
</template>
<script>
import HeaderNavigationItem from './header-navigation-item'
export default {
  name: 'HeaderNavigation',
  components: {
    HeaderNavigationItem,
  },
  props: {
    menuItems: Array,
  },
}
</script>
