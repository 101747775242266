<template>
  <div class="login-form-wrapper">
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <form class="login-form mb-3" @submit.prevent="handleSubmit(login)">
        <validation-provider mode="eager" rules="required" v-slot="{ errors, failed }" vid="email">
          <div class="mb-3">
            <vl-input-text
              v-model="user.email"
              :error="failed"
              type="email"
              name="user[email]"
              :label="t[locale]['header.email']"
              onColorBg
            />
            <span class="error-message">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider
          mode="eager"
          rules="required"
          v-slot="{ errors, failed }"
          vid="password"
        >
          <div class="mb-3">
            <vl-input-text
              v-model="user.password"
              :error="failed"
              type="password"
              name="user[password]"
              :label="t[locale]['header.password']"
              onColorBg
            />
            <span class="error-message">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <vl-button
          class="w-100"
          size="small"
          type="primary"
          actionType="submit"
          :font-size="16"
          :loading="loadingButton"
        >{{ t[locale]['header.signin_short'] }}</vl-button>
      </form>
    </validation-observer>
    <div class="d-flex flex-column align-items-center">
      <a
        class="login-form__url"
        :href="resetPasswordRouter()"
      >{{ t[locale]['header.forgot_password_or_login'] }}</a>
      <a
        class="login-form__url"
        :href="registrationRouter()"
      >{{ t[locale]['header.not_registration'] }}</a>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import VlInputText from '@components/@oschad/ui/vl-input-text'
import VlButton from '@components/@oschad/ui/vl-button'

import checkErrors from '@javascript/mixins/checkErrors'
import { removeLocalStorage } from '@javascript/utils/helper-methods'
import locales from './locales'

const RESET_PASSWORD_PAGE_ROUTER = '/restoration/reset_options'
const REGISTRATION_PAGE_ROUTER = '/registration/card'

export default {
  name: 'LoginForm',
  components: {
    VlInputText,
    VlButton,
  },
  mixins: [checkErrors],
  inject: ['locale', 'defaultLocale'],
  props: {
    providerId: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    t: locales,
    user: {
      email: null,
      password: null,
    },
    loadingButton: false,
  }),
  computed: {
    ...mapGetters({
      getFormErrors: 'authorization/getFormErrors',
    }),
    apiLocale() {
      return this.locale === this.defaultLocale ? null : this.locale
    },
  },
  methods: {
    ...mapActions({
      API_LOGIN: 'authorization/API_LOGIN',
    }),
    async login() {
      this.toggleLoadingButton(true)
      try {
        const { data } = await this.API_LOGIN({
          ...this.user,
          id: this.providerId,
          locale: this.apiLocale,
        })
        removeLocalStorage('email_confirmed')
        removeLocalStorage('thank_cancel')
        this.redirectTo(data.redirect_to)
      } catch (error) {
        this.showAllErrors(error, false, 3000)
        this.showFormErrors()
      } finally {
        this.toggleLoadingButton(false)
      }
    },
    toggleLoadingButton(value) {
      this.loadingButton = value
    },
    redirectTo(url) {
      window.location.replace(url)
    },
    showFormErrors() {
      this.$refs.form.setErrors({ email: [''], password: [''] })
    },
    resetPasswordRouter() {
      if (this.locale == this.defaultLocale) {
        return RESET_PASSWORD_PAGE_ROUTER
      }
      return `/${this.locale}${RESET_PASSWORD_PAGE_ROUTER}`
    },
    registrationRouter() {
      if (this.locale == this.defaultLocale) {
        return REGISTRATION_PAGE_ROUTER
      }
      return `/${this.locale}${REGISTRATION_PAGE_ROUTER}`
    },
  },
}
</script>
