<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="icon-base"
    :height="height"
    :viewBox="['0 0', viewBoxWidth, viewBoxHeight]"
    :width="width"
    :aria-labelledby="iconName"
    role="presentation"
    fill="none"
  >
    <g :fill="iconColor" :opacity="opacity">
      <slot />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'IconBase',
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    viewBoxWidth: {
      type: [Number, String],
      default: 24,
    },
    viewBoxHeight: {
      type: [Number, String],
      default: 24,
    },
    opacity: {
      type: [Number, String],
      default: 1,
    },
  },
}
</script>
