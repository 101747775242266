<template>
  <button
    :class="[
      'vl-btn',
      type ? 'vl-btn--type-' + type : 'vl-btn--type-primary',
      size ? 'vl-btn--size-' + size : 'vl-btn--size-large',
      align ? 'vl-btn--align-' + align : 'd-flex justify-content-center align-items-center',
      textTransform ? 'vl-btn--text-transform-' + textTransform : '',
      {
        'vl-btn--disabled': disabledButton,
        'vl-btn--loading': loading,
      },
    ]"
    :style="{
      maxWidth: maxWidth + 'px',
      maxHeight: maxHeight + 'px',
      minWidth: minWidth + 'px',
      fontSize: fontSize + 'px',
    }"
    :disabled="disabledButton"
    :type="actionType"
  >
    <template v-if="loading">
      <div class="vl-btn__loading-icon">
        <vl-icon-base icon-name="loader" width="24" height="24">
          <vl-icon-loader />
        </vl-icon-base>
      </div>
    </template>
    <template>
      <slot />
    </template>
  </button>
</template>
<script>
import VlIconBase from '@components/@oschad/ui/vl-icons'
import VlIconLoader from '@components/@oschad/ui/vl-icons/VlIconLoader'
export default {
  name: 'VlButton',
  components: {
    VlIconBase,
    VlIconLoader,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'large',
      validator: val =>
        [
          'small',
          'medium',
          'large',
          'bordered',
          'icon',
          'inline',
          'full',
          'link',
        ].includes(val),
    },
    align: {
      type: String,
      default: null,
      validator: val => ['left', 'center', 'right'].includes(val),
    },
    textTransform: {
      type: String,
      default: 'inherit',
      validator: val => ['lowercase', 'uppercase'],
    },
    maxWidth: {
      type: String,
      default: null,
    },
    maxHeight: {
      type: String,
      default: null,
    },
    minWidth: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: [Number, String],
      default: null,
    },
    actionType: {
      type: String,
      default: 'button',
    },
  },
  computed: {
    disabledButton() {
      return this.disabled || this.loading
    },
  },
}
</script>
