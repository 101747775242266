<template>
  <footer class="footer">
    <div class="footer__top">
      <div class="container">
        <div class="row">
          <div class="col d-flex flex-column flex-md-row mb-md-2 mb-4">
            <footer-navigation
              class="footer__navigation mb-md-2 mb-4"
              :footer-menu="footerMenuData"
            />
            <footer-contacts :phones="footerPhone" :email="footerEmail" />
            <div class="footer__partners d-none d-md-flex d-lg-none">
              <div class="footer__partner-item">
                <img :src="require('@javascript/packs/images/pci-logo.png')" alt="pci-logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mb-2">
      <vl-information-static-block :text="renderUserTokenAndTextCallCenter" />
    </div>

    <div class="footer__bottom">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-24 col-md-12 col-lg-16 order-2 order-md-1 order-lg-1">
            <p class="footer__copyright" v-html="copyrightText"></p>
          </div>
          <div class="col-24 col-md-12 col-lg-8 order-1 order-md-2 order-lg-2 mb-4 mb-md-0 d-block d-md-none d-lg-block">
            <div class="footer__partners">
              <div class="footer__partner-item">
                <img :src="require('@javascript/packs/images/pci-logo.png')" alt="pci-logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import FooterNavigation from './footer-navigation'
import FooterContacts from './footer-contacts'
import VlInformationStaticBlock from '@components/@oschad/ui/vl-information-static-block'
import locales from './locales'
export default {
  name: 'Footer',
  components: {
    FooterNavigation,
    FooterContacts,
    VlInformationStaticBlock,
  },
  props: {
    footerMenu: {
      type: String,
      default: null,
    },
    footerCopyright: {
      type: String,
      default: null,
    },
    footerPhone: {
      type: Array,
      default: [],
    },
    footerEmail: {
      type: String,
      default: null,
    },
    userToken: {
      type: String,
      require: true,
    },
  },
  inject: ['locale'],
  data: () => ({
    t: locales,
    footerMenuData: null,
  }),
  computed: {
    renderUserTokenAndTextCallCenter() {
      return `${this.t[locale]['footer.information_about_callcenter']} ${this.userToken}`
    },
    renderCurrentYear() {
      return new Date().getFullYear()
    },
    copyrightText() {
      return `&copy; ${this.renderCurrentYear} ${this.t[locale]['footer.copyright']}`
    }
  },
  created() {
    this.footerMenuData = JSON.parse(this.footerMenu)
  },
}
</script>
