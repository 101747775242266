import axios from "../api/axios/config";
import baseUrl from "../api/baseUrl";

const formatData = data => {
  return {
    url: window.location.href,
    message: 'JS front exception',
    text: data,
  }
}

export default function savingErrorsToCore(error) {
  axios.post(`${baseUrl}/errors.json`, formatData(error))
}
