export default {
  data: () => ({
    isActive: false,
  }),
  methods: {
    blur() {
      if (this.isEmpty(this.$refs.input) && !this.placeholder) {
        this.isActive = false
      }
    },
    focus() {
      this.isActive = true
    },
    isEmpty(node) {
      return node.value ? false : true
    },
    defaultStateLegend() {
      if (!this.isEmpty(this.$refs.input) || this.placeholder) {
        this.focus()
      }
    },
  },
  computed: {
    filled() {
      return this.value && !this.hasFocus
    },
  },
  mounted() {
    this.defaultStateLegend()
  },
}
