import locales from './locales'
import { locale } from '@javascript/config/configuration'

export default {
  serializeBonus: value => {
    let titles = [
        locales[locale]['filters.bonusForm1'],
        locales[locale]['filters.bonusForm2'],
        locales[locale]['filters.bonusForm3'],
      ],
      number = value && parseInt(value) ? parseInt(value) : titles[2],
      cases = [2, 0, 1, 1, 1, 2]

    return titles[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ]
  },
  serializePoint: value => {
    let titles = [
        locales[locale]['filters.pointForm1'],
        locales[locale]['filters.pointForm2'],
        locales[locale]['filters.pointForm3'],
      ],
      number = value && parseInt(value) ? parseInt(value) : titles[2],
      cases = [2, 0, 1, 1, 1, 2]

    return titles[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ]
  },
}
