import {
  apiCheckUserStatus,
  apiFinishRegistration,
  apiRegistration,
} from '@javascript/api/registration'
import { redirectTo } from '@javascript/utils/helper-methods'

export default {
  namespaced: true,
  state: {
    account: null,
    formErrors: {},
  },

  getters: {
    getFormErrors: state => state.formErrors,
  },

  actions: {
    async createAccount({ commit }, userData) {
      return apiRegistration(userData)
    },
    API_FINISH_REGISTRATION({ commit }, userData) {
      return apiFinishRegistration(userData)
    },
    API_CHECK_USER_STATUS({ commit }) {
      return apiCheckUserStatus()
    },
  },

  mutations: {
    redirectTo(state, { path }) {
      redirectTo(path)
    },
    setFormErrors(state, errors) {
      const errorList = Object.values(errors).reduce((acc, item) => {
        return {
          ...acc,
          ...item,
        }
      }, {})
      state.formErrors = errorList
    },
  },
}
