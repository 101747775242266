import { apiRefillMobile } from '@javascript/api/portmone'

export default {
  namespaced: true,
  state: {},

  getters: {},

  actions: {
    async API_REFILL_MOBILE({ commit }, data) {
      return apiRefillMobile(data)
    },
  },

  mutations: {},
}
