import Vue from 'vue'
import Vuex from 'vuex'
import CatalogProduct from './modules/catalog-product'
import Cart from './modules/cart'
import Registration from './modules/registration'
import Profile from './modules/profile'
import Navigation from './modules/navigation'
import Authorization from './modules/authorization'
import Notification from './modules/notification'
import ResetPassword from './modules/reset-password'
import Portmone from './modules/portmone'
import Wishlist from './modules/wishlist'
import ProductAddress from './modules/product-address'
import FAQ from './modules/faq'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    catalogProduct: {
      ...CatalogProduct,
    },
    cart: {
      ...Cart,
    },
    registration: {
      ...Registration,
    },

    profile: {
      ...Profile,
    },
    navigation: {
      ...Navigation,
    },
    authorization: {
      ...Authorization,
    },
    notification: {
      ...Notification,
    },
    resetPassword: {
      ...ResetPassword,
    },
    portmone: {
      ...Portmone,
    },
    wishlist: {
      ...Wishlist,
    },
    productAddress: {
      ...ProductAddress,
    },
    faq: {
      ...FAQ,
    }
  },
})

export default store
