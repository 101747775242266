import { apiGetAddresses, apiGetCities } from '@javascript/api/product-address'

export default {
  namespaced: true,
  state: {
    addresses: null,
    pagesCount: 1,
    pageNumber: 1,
    findingCities: [],
    visiblePreloader: true,
    visiblePreloaderCities: false,
  },
  getters: {
    addresses: state => state.addresses,
    visibleShowMoreButton: state => state.pageNumber < state.pagesCount,
    visiblePreloader: state => state.visiblePreloader,
    visibleEmptyMessage: state =>
      (!state.visiblePreloader && !state.addresses) ||
      (state.addresses && !state.addresses.length),
    hasAddresses: state => !!state.addresses && !!state.addresses.length,
    findingCities: state => state.findingCities,
    visiblePreloaderCities: state => state.visiblePreloaderCities,
  },
  actions: {
    async API_GET_ADDRESSES({ state, commit }, filters) {
      commit('togglePreloader', true)
      try {
        const { data } = await apiGetAddresses({
          ...filters,
          pageNumber: state.pageNumber,
        })
        commit('setAddressesList', data.addresses)
        commit('setPagesCount', data.pagination.pages)
        return Promise.resolve()
      } catch (e) {
        commit('setAddressesList', [])
        return Promise.reject(e)
      } finally {
        commit('togglePreloader', false)
      }
    },
    async API_GET_CITIES({ state, commit }, params) {
      commit('togglePreloaderCities', true)
      try {
        if (params.city.length < 2) {
          throw false
        }
        let { data } = await apiGetCities(params)
        commit('findingCities', data)
        return Promise.resolve()
      } catch (error) {
        commit('findingCities', [])
        return Promise.reject(error)
      } finally {
        commit('togglePreloaderCities', false)
      }
    },
  },
  mutations: {
    setAddressesList(state, addresses) {
      if (state.isPagination) {
        state.addresses = [...state.addresses, ...addresses]
      } else {
        state.addresses = addresses
      }
    },
    setPagesCount(state, pageNumber) {
      state.pagesCount = pageNumber
    },
    setFirstPage(state) {
      state.isPagination = false
      state.pageNumber = 1
      state.pagesCount = 1
    },
    setNextPage(state) {
      state.isPagination = true
      state.pageNumber += 1
    },
    togglePreloader(state, value) {
      state.visiblePreloader = value
    },
    togglePreloaderCities(state, value) {
      state.visiblePreloaderCities = value
    },
    findingCities(state, value) {
      state.findingCities = value
    },
  },
}
