<template>
  <div :class="['vl-notification-message', notificationData.type]">
    <div class="vl-notification-message__close-button" @click="closeMessage">
      <vl-icon-base
        :class="[iconCloseColor]"
        :width="16"
        :height="16"
        :viewBoxWidth="16"
        :viewBoxHeight="16"
      >
        <vl-icon-close />
      </vl-icon-base>
    </div>
    <div v-if="title" class="vl-notification-message__title">{{title}} <span v-if="notificationData.showEmoji">&#128577;</span></div>
    <div v-if="notificationData.description" class="vl-notification-message__desc" v-html="description"></div>
  </div>
</template>
<script>
import VlIconBase from '../../../vl-icons'
import VlIconClose from '../../../vl-icons/VlIconClose'
export default {
  name: 'VlNotificationMessage',
  components: {
    VlIconBase,
    VlIconClose,
  },
  props: {
    notificationData: {
      type: Object,
      default: {},
    },
    titleTranslate: {
      type: String,
      default: '',
    },
  },
  filters: {
    emptyValue(value) {
      return value || ''
    },
  },
  data() {
    return {
      timer: null,
      defaultTimeout: 10000,
    }
  },
  methods: {
    startTimer() {
      if (this.notificationData.permanent) {
        return
      }
      let timeout = this.notificationData.timeout || this.defaultTimeout
      this.timer = setTimeout(() => {
        this.closeMessage()
      }, timeout)
    },
    endTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    closeMessage() {
      this.endTimer()
      this.$emit('closeMessage')
    },
  },
  computed: {
    iconCloseColor() {
      return `vl-notification-message__close-button--${this.notificationData.type}`
    },
    title() {
      return this.notificationData.title
        ? this.notificationData.title
        : this.notificationData.type === 'danger'
        ? this.titleTranslate
        : ''
    },
    description() {
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.notificationData.description, "text/html");
      return doc.body.innerText
    }
  },
  watch: {
    notificationData() {
      this.endTimer()
      this.startTimer()
    },
  },
  created() {
    this.startTimer()
  },
}
</script>
