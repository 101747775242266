import axios from './axios'
import baseUrl from './baseUrl'
import { removeAllSumbolsExceptNumbers } from '@javascript/utils/helper-methods'

let interfaceEditProfile = data => {
  let user = {}
  switch (data.bankName) {
    case 'oschad':
      user = {
        first_name: data.firstName,
        last_name: data.lastName,
        phone: removeAllSumbolsExceptNumbers(data.phone),
        phone_code: data.dialCode,
        language: data.language,
        profile_attributes: {
          gender: data.gender,
          city: data.city,
          birth_date: data.birthDateObject,
          monthly_statement: data.monthlyStatement,
          mailing_information: data.mailingInformation,
        },
      }
      break
    case 'maib':
      user = {
        first_name: data.firstName,
        last_name: data.lastName,
        phone: removeAllSumbolsExceptNumbers(data.phone),
        phone_code: data.dialCode,
        language: data.language,
        profile_attributes: {
          gender: data.gender,
          city: data.city,
          birth_date: data.birthDateObject,
          monthly_statement: data.monthlyStatement,
          mailing_information: data.mailingInformation,
        },
      }
      break
    case 'privat':
      user = {
        language: data.language,
        profile_attributes: {
          gender: data.gender,
          city: data.city,
          mailing_information: data.mailingInformation,
        },
      }
      break
  }

  return { user }
}

let interfaceSetSecretWord = data => {
  return {
    user: {
      profile_attributes: {
        secret_word: data.secretWord,
        sw_clue: data.swClue,
      },
    },
  }
}

let interfaceEditCard = data => {
  return {
    card_id: data.id,
    product_id_description: data.product_id_description,
  }
}

let interfaceChangePassword = data => {
  return {
    user: {
      old_password: data.password,
      password: data.newPassword,
      password_confirmation: data.passwordConfirmation,
    },
    locale: data.locale,
  }
}

let interfaceDataTransactions = data => {
  return {
    params: {
      page: data.pageNumber,
      card_id: data.card_id ? data.card_id : null,
      ttype: data.ttype ? data.ttype : null,
      from_date: data.dateFrom,
      to_date: data.dateTo,
      filter_by_sourceable_created_at: data.filter_by_sourceable_created_at,
    },
  }
}

let interfaceDataBalances = data => {
  return {
    params: {
      page: data.pageNumber,
      from_date: data.dateFrom,
      to_date: data.dateTo,
    },
  }
}

let interfaceAddCard = data => {
  return {
    test_mode: data.testMode ? data.testMode : null,
  }
}

let interfaceDeleteCard = data => {
  return {
    id: data.id,
    locale: data.locale,
  }
}

let interfaceIframeUrl = locale => {
  return {
    params: {
      locale,
    },
  }
}

let interfaceApiVerifyEmail = data => {
  return {
    verify_code: data.verifyCode,
    locale: data.locale,
  }
}

let interfaceDataOrderPDF = data => {
  return `order_item_id=${data.id}&order_id=${data.order_id}`
}

let interfaceSetTaxInfo = data => {
  return {
    pib: data.initials,
    document: data.passportSerial,
    doc_issue_place: data.issuingAuthority,
    doc_issue_date: data.dateIssue,
    ipn: data.ipn,
    allow_tax_pay: data.allowTaxPay,
  }
}

let interfaceChangeEmail = data => {
  return {
    user: {
      email: data.email,
      password: data.password,
    },
  }
}

let interfaceSendMail = data => {
  return {
    user_name: data.name,
    email: data.email,
    subject: data.topic,
    question_text: data.message,
  }
}

let interfaceDataPDFBalance = data => {
  if (!data.ttype && !data.card_id) {
    return `from_date=${data.dateFrom}&to_date=${data.dateTo}&pdf=${data.pdf}&filter_by_sourceable_created_at=${data.filter_by_sourceable_created_at}`
  } else if (!data.card_id && data.ttype) {
    return `from_date=${data.dateFrom}&to_date=${data.dateTo}&ttype=${data.ttype}&pdf=${data.pdf}&filter_by_sourceable_created_at=${data.filter_by_sourceable_created_at}`
  } else if (!data.ttype && data.card_id) {
    return `from_date=${data.dateFrom}&to_date=${data.dateTo}&card_id=${data.card_id}&pdf=${data.pdf}&filter_by_sourceable_created_at=${data.filter_by_sourceable_created_at}`
  } else {
    return `from_date=${data.dateFrom}&to_date=${data.dateTo}&ttype=${data.ttype}&card_id=${data.card_id}&pdf=${data.pdf}&filter_by_sourceable_created_at=${data.filter_by_sourceable_created_at}`
  }
}

let interfaceEditAgent = data => {
  if (data.agentPhone) {
    return {
      user: {
        profile_attributes: {
          is_agent: data.isAgent,
          reference_to_agent_phone: removeAllSumbolsExceptNumbers(data.agentPhone),
        },
      },
    }
  } else {
    return {
      user: {
        profile_attributes: {
          is_agent: data.isAgent,
        },
      },
    }
  }
}

export let apiEditUser = data => {
  let dataApi = interfaceEditProfile(data)
  return axios.patch(`${baseUrl}/profile/edit.json`, dataApi)
}

export let apiSetSecretWord = data => {
  let dataApi = interfaceSetSecretWord(data)
  return axios.patch(`${baseUrl}/profile/edit.json`, dataApi)
}

export let apiEditCard = data => {
  let dataApi = interfaceEditCard(data)
  return axios.patch(`${baseUrl}/profile/my_cards.json`, dataApi)
}

export let apiDeleteCard = data => {
  let dataApi = interfaceDeleteCard(data)
  return axios.post(`${baseUrl}/profile/remove_card.json`, dataApi)
}

export let apiTransaction = data => {
  let dataApi = interfaceDataTransactions(data)
  return axios.get(`${baseUrl}/profile/my_balance.json`, dataApi)
}

export let apiBalances = data => {
  let dataApi = interfaceDataBalances(data)
  return axios.get(`${baseUrl}/profile/my_orders.json`, dataApi)
}

export let apiGetOrderPDF = data => {
  let dataApi = interfaceDataOrderPDF(data)
  return `${baseUrl}/profile/my_orders_pdf_file?${dataApi}`
}

export let apiGetOrderGoogleTokens = data => {
  let dataApi = interfaceDataOrderPDF(data)
  return axios.get(`${baseUrl}/profile/my_orders_jwt_tokens?${dataApi}`)
}

export let apiChangePassword = data => {
  let dataApi = interfaceChangePassword(data)
  return axios.patch(`${baseUrl}/profile/change_password.json`, dataApi)
}

export let apiSendVerifyEmailCode = data => {
  return axios.post(`${baseUrl}/profile/send_verify_email.json`, data)
}

export let apiVerifyEmail = data => {
  const dataApi = interfaceApiVerifyEmail(data)
  return axios.post(`${baseUrl}/profile/verify_email.json`, dataApi)
}

export let apiGetIframeUrl = data => {
  let dataApi = interfaceIframeUrl(data)
  return axios.get(`${baseUrl}/profile/draw_iframe.json`, dataApi)
}

export let apiAddCard = data => {
  let dataApi = interfaceAddCard(data)
  return axios.post(`${baseUrl}/profile/add_card/${data.reference}.json`, dataApi)
}

export let apiTaxInfoData = () => {
  return axios.get(`${baseUrl}/profile/tax_info.json`)
}

export let apiSetTaxInfoData = data => {
  let dataApi = interfaceSetTaxInfo(data)
  return axios.post(`${baseUrl}/profile/save_tax.json`, dataApi)
}

export let apiChangeEmail = data => {
  let dataApi = interfaceChangeEmail(data)
  return axios.post(`${baseUrl}/profile/change_email.json`, dataApi)
}

export let apiGetPDFBalance = data => {
  let dataApi = interfaceDataPDFBalance(data)
  return `${baseUrl}/profile/my_balance_pdf_file?${dataApi}`
}

export let apiSendMail = data => {
  let dataApi = interfaceSendMail(data)
  return axios.post(`${baseUrl}/content/mail_question.json`, dataApi)
}

export let apiEditAgent = data => {
  let dataApi = interfaceEditAgent(data)
  return axios.patch(`${baseUrl}/profile/edit.json`, dataApi)
}
