import axios from 'axios'
import getAuthenticityToken from '@javascript/services/authenticityTokenService'
import { errorHandler, successHandler } from './interceptors'

const AUTHENTICITY_TOKEN = getAuthenticityToken()
const options = {
  headers: {
    common: {
      'X-CSRF-Token': AUTHENTICITY_TOKEN,
      'X-Requested-With': 'XMLHttpRequest',
    },
  },
}

const axiosInstance = axios.create(options)

axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)

export default axiosInstance
