<template>
  <div class="wrapper-slider">
    <button class="slick-prev" @click="showPrev"></button>
    <div class="product-slider">
      <VueSlickCarousel v-bind="sliderSetting" ref="carouselCategories">
        <a
          v-for="slide in JSON.parse(this.slides)"
          class="product-slider__item"
          :href="base_url_with_locale + slide.url"
          :key="slide.id"
        >
          <img
            :src="require(`@javascript/packs/images/partners/${slide.img}`)"
            alt=""
          />
          <img
            v-if="slide.logo_img"
            class="product-slider__logo"
            :src="
              require(`@javascript/packs/images/partners/${slide.logo_img}`)
            "
            alt=""
          />
        </a>
      </VueSlickCarousel>
    </div>
    <button class="slick-next" @click="showNext"></button>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'ProductSlider',
  props: ['slides', 'base_url_with_locale'],
  components: { VueSlickCarousel },

  data() {
    return {
      sliderSetting: {
        infinite: true,
        // touchMove: false,
        arrows: false,
        slidesToShow: 4,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
            },
          },
          // {
          //   breakpoint: 480,
          //   settings: {
          //     slidesToShow: 1,
          //   },
          // },
        ],
      },
    }
  },

  methods: {
    // formattingURL(url) {
    //   return `${this.baseUrlWithLocale}${url}`
    // },
    showNext() {
      this.$refs.carouselCategories.next()
    },
    showPrev() {
      this.$refs.carouselCategories.prev()
    },
  },

  mounted() {
    // console.log(JSON.parse(this.slides))
    // console.log(33, this.base_url_with_locale)
  },
}
</script>
