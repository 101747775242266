import {
  apiWishlist,
  apiAddProductToWishList,
  apiRemoveProductFomWishList,
} from '@javascript/api/wishlist'

export default {
  namespaced: true,
  state: {
    items: [],
    pageNumber: 1,
    pagesCount: 1,
    loading: true,
  },

  getters: {
    wishlistProducts: state => {
      return state.items
    },
    wishlistTotalCount: state => {
      return state.items.length
    },
    pagesCount: state => state.pagesCount,
    pageNumber: state => state.pageNumber,
    loading: state => state.loading,
  },
  mutations: {
    setPagesCount(state, pageNumber) {
      state.pagesCount = pageNumber
    },
    setPageNumber(state, pageNumber) {
      state.pageNumber = pageNumber
    },
    setItems(state, { products, pagination }) {
      if (pagination) {
        state.items = products
      } else {
        state.items = [...state.items, ...products]
      }
    },
    removeItem(state, productId) {
      state.items = state.items.filter(item => item.id != productId)
    },
    loading(state, value) {
      state.loading = value
    },
  },
  actions: {
    async API_WISHLIST({ commit }, { pageNumber, pagination = true }) {
      commit('loading', true)
      try {
        const { data } = await apiWishlist(pageNumber)
        commit('setPageNumber', pageNumber)
        commit('setPagesCount', data.pagination.pages)
        commit('setItems', { products: data.products, pagination })
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('loading', false)
      }
    },
    async API_ADD_TO_WISHLIST({ commit }, productId) {
      try {
        let response = await apiAddProductToWishList(productId)
        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async API_REMOVE_FROM_WISHLIST({ getters, commit, dispatch }, productId) {
      try {
        let { data } = await apiRemoveProductFomWishList(productId)
        commit('removeItem', data.catalog_product_id)
        if (!getters.wishlistTotalCount) {
          if (getters.pageNumber < getters.pagesCount) {
            dispatch('API_WISHLIST', { pageNumber: getters.pageNumber })
          } else if (
            getters.pageNumber == getters.pagesCount &&
            getters.pagesCount != 1
          ) {
            let prevPage = getters.pageNumber - 1
            dispatch('API_WISHLIST', { pageNumber: prevPage })
          }
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
