import { localize } from 'vee-validate'
import locales from './locales'

const getMessages = locale => {
  return {
    messages: {
      email: locales[locale]['validates.email'],
      password: locales[locale]['validates.password'],
      required: locales[locale]['validates.required'],
      integer: locales[locale]['validates.integer'],
      confirmed: locales[locale]['validates.confirmed'],
      date: locales[locale]['validates.date'],
      is: locales[locale]['validates.is'],
      alpha: locales[locale]['validates.alpha'],
      max_amount: locales[locale]['validates.max_amount'],
      max_amount_bonuses: locales[locale]['validates.max_amount_bonuses'],
      min: `${locales[locale]['validates.min']} {min}`,
      max: `${locales[locale]['validates.max']} {max}`,
      minmax: `${locales[locale]['validates.minmax_start']} {min} ${locales[locale]['validates.minmax_middle']} {max} ${locales[locale]['validates.minmax_end']}`,
      invalidDate: locales[locale]['validates.date'],
      validPhone: locales[locale]['validates.phone'],
      validIPNLength: `${locales[locale]['validates.ipn']} {length}`,
      validPersonalNumber: locales[locale]['validates.validPersonalNumber'],
      distinct: locales[locale]['validates.distinct'],
      validPassword: locales[locale]['validates.validPassword'],
    },
  }
}

export default locale => {
  let messagesObject = getMessages(locale)
  localize(locale, messagesObject)
}
