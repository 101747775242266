import axios from './axios'
import baseUrl from './baseUrl'

import { removeAllSumbolsExceptNumbers } from '@javascript/utils/helper-methods'

let interfaceDataCreatePassword = data => {
  return {
    password: data.newPassword,
    password_confirmation: data.passwordConfirmation,
    locale: data.locale,
  }
}

let interfaceApiVerificationCode = data => {
  return {
    code: data.code,
    urlApi: data.urlApi,
    locale: data.locale,
  }
}

let interfaceApiResetPasswordByEmail = data => {
  return {
    email: data.email,
    phone: removeAllSumbolsExceptNumbers(data.phone),
    locale: data.locale,
    recaptcha_token: data.recaptchaToken,
  }
}

export let apiResetPasswordByEmail = data => {
  let dataApi = interfaceApiResetPasswordByEmail(data)
  return axios.post(`${baseUrl}/restoration/reset_option2.json`, dataApi)
}

export let apiVerificationCode = data => {
  let dataApi = interfaceApiVerificationCode(data)
  return axios.post(
    `${baseUrl}/restoration/reset_option2_verify_code.json`,
    dataApi
  )
}

export let apiCreateNewPassword = data => {
  let dataApi = interfaceDataCreatePassword(data)
  return axios.post(`${data.urlApi}.json`, dataApi)
}
