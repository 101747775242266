import axios from './axios'
import baseUrl from './baseUrl'

const interfaceAddressesList = data => ({
  params: {
    page: data.pageNumber,
    city: data.city,
    supplier_url: data.partner,
  },
})

const interfaceCitiesList = params => ({
  params: {
    city: params.city,
    supplier_url: params.supplier_url
  },
})

export let apiGetAddresses = data => {
  let dataApi = interfaceAddressesList(data)
  return axios.get(`${baseUrl}/catalog/city_addresses.json`, dataApi)
}

export let apiGetCities = (params) => {
  let dataApi = interfaceCitiesList(params)
  return axios.get(`${baseUrl}/catalog/city_autocomplete.json`, dataApi)
}
