import { isNull } from 'lodash'
import orderApi from '@javascript/api/order'

const productInterface = product => {
  return {
    id: product.id,
    title: product.title,
    sitePrice: product.site_price,
    siteCurrency: product.order_info.site_currency,
    price: product.order_info.price,
    priceCurrency: product.order_info.price_currency,
    total: product.order_info.item_total,
    outOfStock: product.order_info.out_of_stock,
    quantity: product.order_info.quantity,
    stockCount: product.order_info.stock_count,
    backgroundImage: product.picture,
    image: product.order_info?.supplier_picture?.medium,
    nominalDescription: product.nominal_description,
    predictableTaxes: product.order_info.predictable_taxes,
    commission: product.order_info.commission,
    hideNominal: product.order_info.hide_nominal,
    productType: product.product_type,
  }
}

export default {
  namespaced: true,
  state: {
    userId: null,
    items: [],
    isLoading: false
  },

  getters: {
    cartProducts: state => {
      return state.items
    },
    cartTotalPrice: state => {
      return state.items.reduce((total, product) => {
        return total + product.sitePrice * product.quantity
      }, 0)
    },
    cartTotalCount: state => {
      return state.items.reduce((total, product) => {
        return total + product.quantity
      }, 0)
    },
    isLoading: state => state.isLoading,
  },

  actions: {
    addProductToCart({ state, commit }, product) {
      if (product.stockCount > 0) {
        const cartItem = state.items.find(item => item.id === product.id)
        if (!cartItem) {
          commit('pushProductToCart', {
            ...product,
          })
        } else {
          commit('incrementItemQuantity', cartItem)
        }
      }
    },
    removeProductFromCart({ state, commit }, product) {
      const index = state.items.findIndex(item => item.id === product.id)

      if (index || index === 0) {
        commit('pushProductFromCart', index)
      }
    },
    async API_CREATE_ORDER({ commit }, dataOrder) {
      return orderApi.createOrder(dataOrder)
    },
    async API_TAX_PAY({ commit }, dataOrder) {
      return orderApi.taxPay(dataOrder)
    },
    async API_recalculate({ commit, state: { items: cardItems } }) {
      commit('loading', true)
      const { data: { items = [] } = {} } =
        cardItems.length > 0
          ? await orderApi.recalculate(cardItems.map(({ id, quantity }) => ({ id, quantity })))
          : []
      // if (!items.length) return cardItems
      // commit('initialiseStore', items.map(productInterface))
      commit('loading', false)
      return items.map(productInterface)
    },
  },

  mutations: {
    initialiseStore(state, data) {
      if (!isNull(data)) {
        state.items = data
      }
    },
    pushProductToCart(
      state,
      {
        id,
        title,
        stockCount,
        sitePrice,
        siteCurrency,
        price,
        priceCurrency,
        nominalDescription,
        hideNominal,
        backgroundImage,
        image,
        total,
        predictableTaxes,
        productType,
      }
    ) {
      state.items.push({
        id,
        title,
        sitePrice,
        siteCurrency,
        price,
        priceCurrency,
        stockCount,
        quantity: 1,
        nominalDescription,
        hideNominal,
        backgroundImage,
        image,
        total,
        predictableTaxes,
        productType,
      })
    },
    pushProductFromCart(state, idx) {
      state.items = state.items.slice(0, idx).concat(state.items.slice(idx + 1))
    },
    incrementItemQuantity(state, { id }) {
      const cartItem = state.items.find(item => item.id === id)
      cartItem.quantity++
    },
    decrementItemQuantity(state, { id }) {
      const cartItem = state.items.find(item => item.id === id)
      cartItem.quantity--
    },
    setUserId(state, id) {
      state.userId = id
    },
    loading(state, value) {
      state.isLoading = value
    },
  },
}
