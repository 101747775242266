<template>
  <div class="alerts-list" v-if="showAlert">
    <div :class="['alert alert-dismissible fade show', `alert-${type}` ]" role="alert">
      {{alert}}
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        @click="hideAlert"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VlAlert',
  props: {
    alert: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'warning',
    },
  },
  data() {
    return {
      show: true,
    }
  },
  methods: {
    hideAlert() {
      this.show = false
    },
  },
  computed: {
    showAlert() {
      return !!this.alert && this.show
    },
  },
}
</script>
