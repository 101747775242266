import axios from './axios'
import baseUrl from './baseUrl'

import { removeAllSumbolsExceptNumbers } from '@javascript/utils/helper-methods'

const interfaceRegistration = data => {
  const bankUserId = data.personalNumber ? data.personalNumber : ''
  return {
    user: {
      email: data.email,
      password: data.password,
      first_name: data.firstName,
      last_name: data.lastName,
      phone: removeAllSumbolsExceptNumbers(data.phone),
      phone_code: data.dialCode,
      language: data.language,
      recaptcha_token: data.recaptchaToken,
      bank_user_id: bankUserId,
      profile_attributes: {
        city: data.city,
        birth_date: data.birthDate,
        gender: data.gender,
        monthly_statement: data.monthlyStatement,
        mailing_information: data.mailingInformation,
        secret_word: data.secretWord,
        sw_clue: data.swClue,
      },
    },
  }
}

let interfaceFinishRegistration = data => {
  return {
    user: {
      email: data.email,
      language: data.language,
      recaptcha_token: data.recaptchaToken,
      city: data.city,
      birth_date: data.birthDate,
      gender: data.gender,
      monthly_statement: data.monthlyStatement,
      mailing_information: data.mailingInformation,
      secret_word: data.secretWord,
      sw_clue: data.swClue
    },
  }
}

export function apiRegistration(data) {
  const dataApi = interfaceRegistration(data)
  return axios.post(`${baseUrl}/registration/details.json`, dataApi)
}

export let apiFinishRegistration = data => {
  const dataApi = interfaceFinishRegistration(data)
  return axios.post(`${baseUrl}/registration/finish_registration.json`, dataApi)
}

export let apiCheckUserStatus = () => {
  return axios.get(`${baseUrl}/registration/check_user_status.json`)
}
