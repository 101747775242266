import axios from './axios'
import baseUrl from './baseUrl'
import { removeAllSumbolsExceptNumbers } from '@javascript/utils/helper-methods'

let interfApiDataRefillMobile = data => {
  return {
    phone_value: removeAllSumbolsExceptNumbers(data.phone),
    quantity: data.price,
  }
}

export let apiRefillMobile = data => {
  let dataApi = interfApiDataRefillMobile(data)
  return axios.post(`${baseUrl}/portmone/top_up_mobile.json`, dataApi)
}
