import productApi from '@javascript/api/product'

export default {
  namespaced: true,
  state: {
    products: [],
    errors: [],
    pages: 0,
    page: 1,
    visiblePreloader: false,
  },
  getters: {
    getProducts: state => state.products,
    getCatalogErrors: state => state.errors,
    getTotalPage: state => state.pages,
    getCurrentPage: state => state.page,
    visiblePreloader: state => state.visiblePreloader,
  },
  actions: {
    async loadProductList(
      { commit, getters: { visiblePreloader } = {} },
      { category, page, sortOrder, ...rest }
    ) {
      if (visiblePreloader) return
      commit('togglePreloader', true)

      const paramsHasCategory = { category, page, ...rest }
      const paramsHasNotCategory = { page, ...rest }
      const requestParams =
        category === 'all' ? paramsHasNotCategory : paramsHasCategory
      try {
        const { data } = await productApi.fetchRecords({
          ...requestParams,
          sortOrder,
        })
        commit('setProductList', data.products)
        commit('setPages', data.pagination)
        commit('setCatalogErrors', [])
      } catch (e) {
        console.log(e)
        commit('setCatalogErrors', e.response.data.errors)
      } finally {
        commit('togglePreloader', false)
      }
    },
  },
  mutations: {
    setProductList(state, products) {
      state.products = [...state.products, ...products]
    },
    setCatalogErrors(state, errors) {
      state.errors = [...errors]
    },
    setPages(state, pagination) {
      state.pages = pagination.pages
      state.page = pagination.page
    },
    clearProductList(state) {
      state.products.length = 0
    },
    togglePreloader(state, value) {
      state.visiblePreloader = value
    },
  },
}
