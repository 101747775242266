import axios from './axios'
import baseUrl from './baseUrl'

const interfaceTax = data => ({
  pib: data.initials,
  document: data.passportSerial,
  doc_issue_place: data.issuingAuthority,
  doc_issue_date: data.dateIssue,
  ipn: data.ipn,
  allow_tax_pay: data.allowTaxPay,
})

const interfaceSigleOrder = item => {
  return {
    id: item.id,
    quantity: item.quantity,
  }
}
const interfaceTotalOrder = ({ items }) => {
  const productList = items.map(interfaceSigleOrder)
  return {
    items: productList,
  }
}

export function createOrder(data) {
  const dataApi = interfaceTotalOrder(data)
  return axios.post(`${baseUrl}/order/create.json`, dataApi)
}

export function taxPay(data) {
  const dataApi = interfaceTax(data)
  return axios.post(`${baseUrl}/profile/save_tax.json`, dataApi)
}

const recalculate = items => {
  return axios.post(`${baseUrl}/order/show.json`, { items })
}

export default {
  createOrder,
  taxPay,
  recalculate,
}
