<template>
  <ul class="footer-navigation">
    <li
      class="footer-navigation__item"
      v-for="item in footerMenu.children"
      :key="item.id"
    >
      <a
        class="footer-navigation__action-link"
        :href="item.url"
        target="_blank" rel="noopener noreferrer"
      >
        {{ item.title }}
      </a>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'FooterNavigation',
  props: {
    footerMenu: {
      type: Object,
      default: null,
    },
  },
}
</script>
