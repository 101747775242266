<template>
  <div class="popap-container" v-click-outside="clickedOutside">
    <div class="popap-container__arrow-up"></div>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Popap',
  methods: {
    clickedOutside(event) {
      this.$emit('clickOutside')
    },
  },
}
</script>
