import axios from './axios'
import baseUrl from './baseUrl'

const sortColumn = type => {
  const defaultTypes = ['catalog_sort_order', 'site_price']
  return (
    defaultTypes.filter(defaultType => defaultType === type)[0] ||
    defaultTypes[0]
  )
}

const sortDirection = type => {
  const defaultTypes = ['asc', 'desc']
  return (
    defaultTypes.filter(defaultType => defaultType === type)[0] ||
    defaultTypes[0]
  )
}

const interfaceProduct = ({ sortOrder, category, page, ...rest }) => {
  const type = sortOrder ? sortOrder.split('-')[0] : ''
  const value = sortOrder ? sortOrder.split('-')[1] : ''
  const sortType = sortColumn(type)
  const sortValue = sortDirection(value)
  return {
    params: {
      limit: 9,
      category: category,
      page: page,
      sort_column: sortType,
      sort_direction: sortValue,
      ...rest,
    },
  }
}

export function fetchRecords(data) {
  const dataApi = interfaceProduct(data)
  return axios.get(`${baseUrl}/catalog.json`, dataApi)
}

export default { fetchRecords }
