import axios from './axios'
import baseUrl from './baseUrl'

let interfaceFaq = url => {
  return {
    params: { category_url: url }
  }
}

export let apiGetFaqListItems = data => {
  let dataApi = interfaceFaq(data)
  return axios.get(`${baseUrl}/content/all_category_pages.json`, dataApi)
}
