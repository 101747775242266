import { redirectTo } from '../../utils/helper-methods'
import { AUTHORIZATION_LOGIN } from '../../config/routes'
import baseUrl from './../baseUrl'

const urlParams = {
  sessionExpired: 'session_expired=true',
}

const authorizationLoginWithParams = `${baseUrl}${AUTHORIZATION_LOGIN}?${urlParams.sessionExpired}`

const errorHandler = error => {
  if (error.response.status === 401) {
    redirectTo(authorizationLoginWithParams)
  }
  return Promise.reject(error)
}

const successHandler = response => {
  return response
}

export { errorHandler, successHandler }
