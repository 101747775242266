import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, confirmed, is, min, max, integer, required_if } from 'vee-validate/dist/rules'

const matchRegex = (list, value) => {
  let countOfMatches = 0
  for (let i = 0; i < list.length; i++) {
    if (list[i].test(value)) {
      countOfMatches = countOfMatches + 1
    } else {
      countOfMatches = countOfMatches
    }
  }
  return countOfMatches
}

extend('email', {
  validate: (value) => {
    const regex =
      /^([^.])([a-zA-Z0-9!#$%&'*+/=?^_.`{|}~-]){0,50}@[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]{1,50}?(?:\.[a-zA-Z0-9]{1,50}?)*$/gim
    return regex.test(value)
  },
})

extend('password', {
  validate: (value) => {
    const regexList = [
      /^(?=.*[A-Z|\u0400-\u042F])/,
      /^(?=.*[a-z|\u0430-\u044f])/,
      /^(?=.*[\d])/,
      /^(?=.*[-/~!#*$@_%+=.,^&(){}[\]|;:<>?\\])/,
      /^(?!.*[\s])/,
    ]
    // old  !@#$%^&*.
    // return matchRegex(regexList, value) > 2 ? true : false
    return matchRegex(regexList, value) === 5
  },
})

extend('required', {
  ...required,
})

extend('required_if', {
  ...required_if,
})

extend('integer', {
  ...integer,
})

extend('confirmed', {
  ...confirmed,
})

extend('date', {
  validate: (value) => {
    const regex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/
    return regex.test(value)
  },
})

extend('is', {
  ...is,
  validate: (value) => {
    return value
  },
})

extend('alpha', {
  validate: (value) => {
    const regex = /^[^0-9!#$%@&'*+\/=?^_`{|}~(),:;<>\[\]\\]{2,150}$/
    return regex.test(value)
  },
})

extend('min', {
  ...min,
  validate: (value, { min }) => {
    return value.length >= min
  },
  params: ['min'],
})

extend('max', {
  ...max,
  validate: (value, { max }) => {
    return value.length <= max
  },
  params: ['max'],
})

extend('minmax', {
  validate(value, { min, max }) {
    return value.length >= min && value.length <= max
  },
  params: ['min', 'max'],
})

extend('invalidDate', {
  validate: (value, { valid }) => {
    return JSON.parse(valid)
  },
  params: ['valid'],
})

extend('validPhone', {
  validate: (value, { valid }) => {
    return !!value && JSON.parse(valid)
  },
  params: ['valid'],
})

extend('distinct', {
  params: ['target'],
  validate(value, { target }) {
    // console.log(value, target)
    return value !== target
  },
})

extend('validPassword', {
  params: ['email', 'first_name', 'last_name', 'arr'],
  validate(value, { email, first_name, last_name }) {
    if (first_name && last_name && email) {
      let pattern = new RegExp(`${first_name}|${last_name}|${email.split('@')[0]}`, 'gi')
      return !pattern.test(value)
    }
    return true
  },
})

extend('validIPNLength', {
  validate: (value, { length }) => {
    return value.length == length
  },
  params: ['length'],
})

extend('validPersonalNumber', {
  validate: (value) => {
    const regex = /^[A-Za-z0-9]+$/
    return regex.test(value)
  },
})

extend('max_amount', {
  validate: (value, number) => {
    return Number(number) >= Number(value) && Number(value) !== 0
  },
})

extend('max_amount_bonuses', {
  validate: (value, number) => {
    return Number(number) >= Number(value) && Number(value) !== 0
  },
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
