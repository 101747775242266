import {
  apiResetPasswordByEmail,
  apiVerificationCode,
  apiCreateNewPassword,
} from '@javascript/api/reset-password'

export default {
  namespaced: true,
  state: {},

  getters: {},
  mutations: {},
  actions: {
    async API_RESET_PASSWORD_BY_EMAIL({ commit }, data) {
      return apiResetPasswordByEmail(data)
    },
    async API_VERIFICATION_CODE({ commit }, code) {
      return apiVerificationCode(code)
    },
    async API_CREATE_NEW_PASSWORD({ commit }, data) {
      return apiCreateNewPassword(data)
    },
  },
}
