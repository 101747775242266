import navigationApi from '@javascript/api/navigation'

export default {
  namespaced: true,
  state: {
    menu: null,
    profileMenu: null,
  },
  getters: {
    getMenu: state => state.menu,
    getProfileMenu: state => state.profileMenu,
  },
  actions: {
    async loadMenu({ commit }, { key }) {
      try {
        const { data } = await navigationApi.fetchRecord({ key })
        commit('setMenu', data)
      } catch (e) {
        console.error(e)
      }
    },
    async API_PROFILE_MENU({ commit }, { key }) {
      try {
        const { data } = await navigationApi.profileMenu({ key })
        commit('setProfileMenu', data)
      } catch (error) {}
    },
  },
  mutations: {
    setMenu(state, menu) {
      state.menu = menu.children
    },
    setProfileMenu(state, menu) {
      state.profileMenu = menu.children
    },
  },
}
