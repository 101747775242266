<template>
  <ul class="footer-contacts">
    <li
      class="footer-contacts__item"
      v-for="(phone, index) in phones"
      :key="index"
    >
      <i class="icon">
        <vl-icon-base
          :width="10"
          :height="16"
          :viewBoxWidth="10"
          :viewBoxHeight="16"
          iconColor="white"
        >
          <vl-icon-phone />
        </vl-icon-base>
      </i>
      <a :href="`tel:${phone}`">{{ phone }}</a>
    </li>
    <li class="footer-contacts__item">
      <i class="icon">
        <vl-icon-base
          :width="16"
          :height="13"
          :viewBoxWidth="16"
          :viewBoxHeight="13"
          iconColor="white"
        >
          <vl-icon-email />
        </vl-icon-base>
      </i>
      <a :href="`mailto:${email}`">
        {{ email }}
      </a>
    </li>
  </ul>
</template>
<script>
import VlIconBase from '@components/@oschad/ui/vl-icons'
import VlIconPhone from '@components/@oschad/ui/vl-icons/VlIconPhone'
import VlIconEmail from '@components/@oschad/ui/vl-icons/VlIconEmail'
export default {
  name: 'FooterContacts',
  components: {
    VlIconBase,
    VlIconPhone,
    VlIconEmail,
  },
  props: {
    phones: {
      type: Array,
      default: [],
    },
    email: {
      type: String,
      default: null,
    },
  },
}
</script>
