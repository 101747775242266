<template>
  <div class="vl-notification">
    <transition-group name="slide">
      <vl-notification-message
        v-for="(notification, index) in notificationsList"
        :key="notification.id"
        :notification-data="notification"
        @closeMessage="closeMessage(index)"
      />
    </transition-group>
  </div>
</template>
<script>
import VlNotificationMessage from './components/vl-notification-message/VlNotificationMessage'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'VlNotification',
  components: {
    VlNotificationMessage,
  },
  data() {
    return {}
  },
  methods: {
    ...mapMutations({
      removeMessage: 'notification/removeMessage',
    }),
    closeMessage(index) {
      this.removeMessage(index)
    },
  },
  watch: {},
  computed: {
    ...mapGetters({
      notificationsList: 'notification/notificationList',
    }),
    showNotifications() {
      return !!this.notificationsList && !!this.notificationsList.length
    },
  },
  created() {},
}
</script>
