import axios from './axios'
import baseUrl from './baseUrl'

const interfaceAddToWishList = id => ({ catalog_product_id: id })
const interfaceRemoveFromWishList = id => ({ data: { wish_list_id: id } })
const interfaceWishList = pageNumber => ({ params: { page: pageNumber } })

export let apiWishlist = pageNumber => {
  let dataApi = interfaceWishList(pageNumber)
  return axios.get(`${baseUrl}/profile/wish_list.json`, dataApi)
}

export let apiAddProductToWishList = productId => {
  let dataApi = interfaceAddToWishList(productId)
  return axios.post(`${baseUrl}/wish_list/add_wish_list.json`, dataApi)
}

export let apiRemoveProductFomWishList = productId => {
  let dataApi = interfaceRemoveFromWishList(productId)
  return axios.delete(`${baseUrl}/wish_list/remove_wish_list.json`, dataApi)
}
