import {
  apiEditUser,
  apiSetSecretWord,
  apiEditCard,
  apiDeleteCard,
  apiTransaction,
  apiBalances,
  apiChangePassword,
  apiSendVerifyEmailCode,
  apiVerifyEmail,
  apiGetIframeUrl,
  apiAddCard,
  apiGetOrderPDF,
  apiGetOrderGoogleTokens,
  apiTaxInfoData,
  apiSetTaxInfoData,
  apiChangeEmail,
  apiGetPDFBalance,
  apiSendMail,
  apiEditAgent,
} from '@javascript/api/profile'

let interfaceTaxInfo = data => ({
  initials: data.pib,
  passportSerial: data.document,
  issuingAuthority: data.doc_issue_place,
  dateIssue: data.doc_issue_date,
  ipn: data.ipn,
  allowTaxPay: data.allow_tax_pay,
})

export default {
  namespaced: true,
  state: { changePasswordApiErrors: [], loading: false },

  getters: {
    changePasswordApiErrors(state) {
      return state.changePasswordApiErrors
    },
    loading: state => state.loading,
  },
  mutations: {
    setChangePasswordApiErrors(state, value) {
      state.changePasswordApiErrors = value
    },
    loading(state, value) {
      state.loading = value
    },
  },
  actions: {
    async API_EDIT_USER({ commit }, dataUser) {
      return apiEditUser(dataUser)
    },
    async API_SET_SECRET_WORD({ commit }, data) {
      return apiSetSecretWord(data)
    },
    async API_EDIT_CARD({ commit }, dataCard) {
      return apiEditCard(dataCard)
    },
    async API_DELETE_CARD({ commit }, dataCard) {
      return apiDeleteCard(dataCard)
    },
    async API_GET_TRANSACTIONS({ commit }, dataFilters) {
      try {
        const { data } = await apiTransaction(dataFilters)
        return data
      } catch (error) {}
    },
    async API_GET_BALANCES({ commit }, dataFilters) {
      try {
        const { data } = await apiBalances(dataFilters)
        return data
      } catch (error) {}
    },
    async API_GET_PDF_ORDERS({ commit }, data) {
      return apiGetOrderPDF(data)
    },
    async API_GET_TOKENS_ORDERS({ commit }, data) {
      return apiGetOrderGoogleTokens(data)
    },
    async API_CHANGE_PASSWORD({ commit }, dataPassword) {
      return apiChangePassword(dataPassword)
    },
    async API_SEND_VERIFY_EMAIL_CODE({ commit }, data) {
      return apiSendVerifyEmailCode(data)
    },
    async API_VERIFY_EMAIL({ commit }, data) {
      return apiVerifyEmail(data)
    },

    async API_GET_IFRAME_URL({ commit }, data) {
      return apiGetIframeUrl(data)
    },
    async API_ADD_CARD({ commit }, data) {
      return apiAddCard(data)
    },
    async API_GET_TAX_INFO({ commit }) {
      try {
        let { data } = await apiTaxInfoData()
        let dataTax = interfaceTaxInfo(data)
        return Promise.resolve(dataTax)
      } catch (error) {
        return Promise.resolve(error)
      }
    },
    async API_POST_TAX_INFO({ commit }, data) {
      return await apiSetTaxInfoData(data)
    },
    async API_CHANGE_EMAIL({ commit }, data) {
      commit('loading', true)
      try {
        await apiChangeEmail(data)
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('loading', false)
      }
    },
    async API_GET_PDF_BALANCE({ commit }, data) {
      return apiGetPDFBalance({
        ...data,
        dateFrom: data.dateFrom.toISOString(),
        dateTo: data.dateTo.toISOString(),
      })
    },
    async API_SEND_MAIL({ commit }, data) {
      return apiSendMail(data)
    },
    async API_EDIT_AGENT({ commit }, data) {
      return apiEditAgent(data)
    },
  },
}
