<template>
  <VueSlickCarousel v-bind="sliderSetting">
    <div
      class="col h-100"
      v-for="(slide, index) in JSON.parse(this.news)"
      :key="index"
    >
      <div class="news-item">
        <div class="news-item__header">
          <div class="news-item__date">{{ slide.date }}</div>

          <h2>{{ slide.title }}</h2>

          <p>{{ slide.content }}</p>
        </div>

        <div class="news-item__footer">
          <a class="news-item__action-link" :href="slide.url">{{
            read_more
          }}</a>
        </div>
      </div>
    </div>
  </VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'NewsSlider',
  props: ['news', 'base_url_with_locale', 'read_more'],
  components: { VueSlickCarousel },

  data() {
    return {
      sliderSetting: {
        infinite: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: true,
        slidesToShow: 3,
        // adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    }
  },

  methods: {
    // formattingURL(url) {
    //   return `${this.baseUrlWithLocale}${url}`
    // },
  },

  mounted() {
    // console.log(JSON.parse(this.news))
    // console.log(33, this.read_more)
  },
}
</script>
