<template>
  <div class="page-header">
    <div class="container">
      <div class="page-header__content" v-if="!title">
        <div class="page-header__title">
          <div class="part_title first_text">{{ firstText }}</div>
          <div class="part_title second_text" v-if="secondText">{{ secondText }}</div>
          <div class="part_title thirth_text" v-if="thirthText">{{ thirthText }}</div>
        </div>
        <slot />
      </div>
      <h2 v-else class="h2-title" v-html="title"></h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
    },
    firstText: {
      type: String,
      default: 'firstText',
    },
    secondText: {
      type: String,
    },
    thirthText: {
      type: String,
    },
  },
}
</script>
