var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'vl-input-group',
    {
      'vl-input-group--active': _vm.isActive,
      'vl-input-group--has-error': _vm.error,
      'vl-input-group--on-color-bg': _vm.onColorBg,
    },
  ]},[_c('fieldset',{class:[
      'vl-input-group__title',
      {
        'vl-input-group__title--active': _vm.isActive,
        'vl-input-group__title--has-error': _vm.error,
      },
    ],attrs:{"aria-hidden":"true"}},[_c('legend',{ref:"legend"},[_c('span',[_vm._v(" ")])])]),_vm._v(" "),_c('div',{staticClass:"vl-input-group__input-slot"},[_c('input',{ref:"input",class:[{ 'vl-input-group__vl-input-password': _vm.type === 'password' && _vm.showEye }, 'vl-input-group__vl-input-text'],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"name":_vm.name,"autocomplete":_vm.autocomplete,"type":_vm.inputType},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"change":_vm.onChangeValue,"blur":_vm.handleBlur,"focus":_vm.handleFocus,"keypress":_vm.keypress}}),_vm._v(" "),_c('label',{class:[
        'vl-input-group__vl-label',
        {
          'vl-input-group__vl-label--active': _vm.isActive,
          'vl-input-group__vl-label--has-error': _vm.error,
        },
      ]},[_c('span',{ref:"label"},[_vm._v(_vm._s(_vm.label))])]),_vm._v(" "),(_vm.$slots.icon && _vm.type !== 'password')?_c('div',{staticClass:"icon"},[_vm._t("icon")],2):(_vm.type === 'password' && _vm.showEye)?_c('div',{staticClass:"icon-eye",on:{"click":function($event){$event.stopPropagation();return _vm.toggleShowPassword.apply(null, arguments)}}},[(_vm.showPassword)?_c('vl-icon-eye-slash'):_c('vl-icon-eye')],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }