import axios from './axios'
import baseUrl from './baseUrl'

const interfaceNavigation = data => {
  return {
    params: {
      key: data.key,
    },
  }
}

export function fetchRecord(data) {
  const dataApi = interfaceNavigation(data)
  return axios.get(`${baseUrl}/menu/draw_menu.json`, dataApi)
}

export let profileMenu = data => {
  const dataApi = interfaceNavigation(data)
  return axios.get(`${baseUrl}/menu/draw_menu.json`, dataApi)
}

export default { fetchRecord, profileMenu }
