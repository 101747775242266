import { apiGetFaqListItems } from '@javascript/api/faq'

export default {
  namespaced: true,
  state: {
    items: new Map()
  },
  getters: {
    getItems: state => url => {
      return state.items.get(url)
    }
  },
  actions: {
    async API_GET_FAQ_LIST_ITEMS({ state, commit}, url) {
      if(!state.items.has(url)) {
        try {
          const response = await apiGetFaqListItems(url)
          commit('setItems', {url, items: response.data})
        } catch (err) {
          throw new Error(err)
        }
      }
    }
  },
  mutations: {
    setItems(state, {url, items}) {
      state.items.set(url, items)
    }
  }
}
