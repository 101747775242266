<template>
  <li
    :class="[
      'header-navigation__item',
      menuItem.children ? 'has-children' : '',
    ]"
  >
    <a :href="menuItem.url" target="_blank" rel="noopener noreferrer">{{ menuItem.title }} </a>
    <ul v-if="menuItem.children" class="header-navigation__sub-menu is-hidden">
      <header-navigation-item
        v-for="(child, index) in menuItem.children"
        :menu-item="child"
        :key="index"
      />
    </ul>
  </li>
</template>
<script>
export default {
  name: 'HeaderNavigationItem',
  props: {
    menuItem: Object,
  },
}
</script>
