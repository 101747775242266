/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Vue from 'vue'
import moment from 'moment'
import PortalVue from 'portal-vue'
import veeValidate from '@javascript/plugins/localization/veeValidate'
import store from '@javascript/vuex/store'
import VlNotificationStatic from '@components/@oschad/ui/vl-notification-static'
import filters from '@javascript/filters/filters'
import clickOutside from '@javascript/directives/click-outside'
import vueAwesomeCountdown from 'vue-awesome-countdown'

import App from './App'
import '../../stylesheets/oschad/application.sass'

Vue.prototype.moment = moment

Vue.use(PortalVue)
Vue.use(vueAwesomeCountdown)

Vue.component('vlNotification', VlNotificationStatic)

for (let filter in filters) {
  Vue.filter(filter, filters[filter])
}

Vue.directive('click-outside', clickOutside)

Vue.prototype.$bus = new Vue()

Vue.config.ignoredElements = ['g:savetoandroidpay']

new Vue({
  el: '#app',
  store,
  mixins: [App],
})
require('@rails/ujs').start()

window.onerror = function(errorMessage, errorUrl, errorLine) {
  const data = {
    userAgent: navigator.userAgent,
    errorMessage,
    errorUrl,
    errorLine,
  }

  fetch('/error_report', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'aplication/json',
    },
  })
  return true
}
