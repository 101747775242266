import {
  authorizationLogin,
  authorizationLogout,
} from '@javascript/api/authorization'
import { redirectTo } from '@javascript/utils/helper-methods'
import { AUTHORIZATION_LOGIN } from '@javascript/config/routes'
import { baseUrlWithLocale } from '@javascript/config/configuration'

export default {
  namespaced: true,
  state: {
    formErrors: {},
  },
  getters: {
    getFormErrors: state => state.formErrors,
  },
  actions: {
    async API_LOGIN({ commit }, dataLogin) {
      return authorizationLogin(dataLogin)
    },
    async API_LOGOUT({ commit }) {
      try {
        const { status } = await authorizationLogout()
        if (status === 200) {
          redirectTo(`${baseUrlWithLocale}${AUTHORIZATION_LOGIN}`)
        }
      } catch (err) {}
    },
    async API_LOGOUT_WITHOUT_REDIRECT({ commit }) {
      try {
        const { status } = await authorizationLogout()
        if (status === 200) {
          return Promise.resolve()
        } else {
          return Promise.reject()
        }
      } catch (err) {
        return Promise.reject()
      }
    },
  },
  mutations: {
    setFormErrors(state, errors) {
      state.formErrors = errors
    },
  },
}
