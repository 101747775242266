<template>
  <div :class="['vl-notification-message', notificationData.type]">
    <div class="vl-notification-message__close-button" @click="closeMessage">
      <vl-icon-base
        :class="[iconCloseColor]"
        :width="16"
        :height="16"
        :viewBoxWidth="16"
        :viewBoxHeight="16"
      >
        <vl-icon-close />
      </vl-icon-base>
    </div>
    <div v-if="notificationData.title" class="vl-notification-message__title">
      {{ notificationData.title | emptyValueTitle }}
    </div>
<!--    <div>{{notificationData.description | emptyValue}}</div>-->
    <div v-html="description"></div>
  </div>
</template>
<script>
import VlIconBase from '../../../vl-icons'
import VlIconClose from '../../../vl-icons/VlIconClose'
export default {
  name: 'VlNotificationMessage',
  components: {
    VlIconBase,
    VlIconClose,
  },
  props: {
    notificationData: {
      type: Object,
      default: {},
    },
  },
  filters: {
    emptyValue(value) {
      return value || ''
    },
    emptyValueTitle(value) {
      return value || ''
    },
  },
  data() {
    return {
      timer: null,
      defaultTimeout: 30000,
    }
  },
  methods: {
    startTimer() {
      if (this.notificationData.permanent) {
        return
      }
      let timeout = this.notificationData.timeout || this.defaultTimeout
      this.timer = setTimeout(() => {
        this.closeMessage()
      }, timeout)
    },
    closeMessage() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.$emit('closeMessage')
    },
  },
  computed: {
    iconCloseColor() {
      return `vl-notification-message__close-button--${this.notificationData.type}`
    },
    description() {
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.notificationData.description, "text/html");
      return doc.body.innerText
    }
  },
  created() {
    this.startTimer()
  },
}
</script>
