<template>
  <header class="header">
    <slot />
    <div class="header__top">
      <div class="container h-100 px-0 px-md-2">
        <div class="row no-gutters justify-content-between h-100">
          <div class="col-auto align-self-center px-2 px-md-0">
            <div class="d-none d-md-block">
              <language-container
                v-if="isHideOnThisPage"
                :locale="locale"
                :default-locale="defaultLocale"
                :available-locales="availableLocales"
                :current-path="currentPath"
              />
            </div>
            <div class="d-md-none">
              <div class="header__mobile-menu-btn" @click="toggleMenu(true)">
                <span></span>
              </div>
            </div>
          </div>
          <!-- desktop wrapper -->
          <div v-if="!isLoginPage" class="col-auto d-none d-md-block">
            <vl-button
              class="h-100 header__login-button header__login-button--is-login"
              size="small"
              v-if="isLogin"
              @click.native.stop="toggleLoginBar"
            >
              <i class="icon-avatar">
                <vl-icon-base iconName="avatar" width="29" height="29" viewBoxWidth="29" viewBoxHeight="29">
                  <vl-icon-avatar />
                </vl-icon-base>
              </i>
              <span class="header__user-greeting">
                {{ t[locale]['header.greeting'] }},
                {{ userName }}
              </span>
            </vl-button>
            <vl-button v-else class="h-100 header__login-button" size="small" @click.native.stop="toggleLoginForm">
              <i class="icon-avatar">
                <vl-icon-base iconName="avatar" width="29" height="29" viewBoxWidth="29" viewBoxHeight="29">
                  <vl-icon-avatar />
                </vl-icon-base>
              </i>
              <span>{{ t[locale]['header.signin'] }}</span>
            </vl-button>
            <popap v-if="isShowLoginForm" @clickOutside="closeModal('isShowLoginForm')">
              <login-form :providerId="providerId" />
            </popap>
            <popap v-if="isShowLoginBar" @clickOutside="closeModal('isShowLoginBar')" class="is_auth_popup">
              <ul class="header-context-menu">
                <li v-for="item of sortedProfileMenu" :key="item.id" class="header-context-menu__item">
                  <a class="header-context-menu__url" :href="item.url">
                    {{ item.title }}
                  </a>
                </li>
                <li class="header-context-menu__item">
                  <span class="header-context-menu__url" @click="API_LOGOUT">
                    {{ t[locale]['authorization.logout.exit'] }}</span>
                </li>
              </ul>
            </popap>
          </div>
          <!-- #desktop wrapper -->
          <!-- mobile wrapper -->
          <div v-if="!isLoginPage" class="col-auto d-lg-none d-md-none">
            <template v-if="isLogin">
              <vl-button
                class="h-100 header__login-button header__login-button--is-login"
                size="small"
                @click.native="toggleMobileMenu"
              >
                <i class="icon-avatar">
                  <vl-icon-base iconName="avatar" width="29" height="29" viewBoxWidth="29" viewBoxHeight="29">
                    <vl-icon-avatar />
                  </vl-icon-base>
                </i>
                <span class="header__user-greeting">
                  {{ t[locale]['header.greeting'] }}
                </span>
              </vl-button>
            </template>
            <template v-else>
              <vl-button class="h-100 header__login-button" size="small" @click.native="toggleMobileMenu">
                <i class="icon-avatar">
                  <vl-icon-base iconName="avatar" width="29" height="29" viewBoxWidth="29" viewBoxHeight="29">
                    <vl-icon-avatar />
                  </vl-icon-base>
                </i>
                <span>{{ t[locale]['header.signin'] }}</span>
              </vl-button>
            </template>
          </div>
          <!-- #mobile wrapper -->
        </div>
      </div>
    </div>
    <div class="header__middle d-lg-none d-md-none">
      <modal v-if="isShowMobileMenu" @click="toggleMobileMenu" :margin-top="20">
        <template v-slot:header>
          <i class="icon-avatar">
            <vl-icon-base iconName="avatar" width="29" height="29" viewBoxWidth="29" viewBoxHeight="29">
              <vl-icon-avatar />
            </vl-icon-base>
          </i>
          <span class="header__user-greeting">
            <template v-if="isLogin">{{ t[locale]['header.greeting'] }}, {{ userName }}</template>
            <template v-else>{{ t[locale]['header.signin'] }}</template>
          </span>
        </template>
        <template v-if="isLogin">
          <ul class="header-context-menu">
            <li v-for="item of sortedProfileMenu" :key="item.id" class="header-context-menu__item">
              <a class="header-context-menu__url" :href="item.url">{{ item.title }}</a>
            </li>
            <li class="header-context-menu__item">
              <span class="header-context-menu__url" @click="API_LOGOUT">
                {{ t[locale]['authorization.logout.exit'] }}</span>
            </li>
          </ul>
        </template>
        <template v-else>
          <div class="header__login-form">
            <login-form :providerId="providerId" />
          </div>
        </template>
      </modal>
      <modal @click="toggleMenu(false)" v-if="isShowMenu" :margin-top="25" cancel-position="left">
        <div class="mb-4">
          <language-container
            v-if="isHideOnThisPage"
            :locale="locale"
            :default-locale="defaultLocale"
            :available-locales="availableLocales"
            :current-path="currentPath"
            row
          />
        </div>
        <div class="header__catalog-title">{{ t[locale]['header.catalog'] }}</div>
        <ul class="header-context-menu">
          <li v-for="item of sortedHeaderMenu" :key="item.id" class="header-context-menu__item">
            <span class="header-context-menu__url" @click.prevent="openLink(item)">{{ item.title }}</span>
          </li>
        </ul>
      </modal>
    </div>
    <div class="header__bottom">
      <div class="container">
        <div class="row justify-content-between flex-nowrap align-items-center">
          <div class="header__wrapper-logo col-auto d-flex pr-0 pr-md-2">
            <a class="header__wrapper-logo--logo" :href="homeRouter()" rel="home">
              <img
                :src="require('@javascript/packs/images/oschad-logo.svg')"
                class="d-inline-block"
                alt="OschadBank"
                height="40"
                width="195"
              />
            </a>
          </div>
          <template v-if="isLogin">
            <div
              class="header__user-info col-auto d-flex align-items-start justify-content-center pl-0 pl-md-2 pr-0 pr-md-2"
            >
              <div class="d-flex align-items-center">
                <a class="catalog-link" :href="catalogRouter()">
                  {{ t[locale]['header.catalog'] }}
                </a>
                <div class="header__user-balance user-balance">
                  <div class="user-balance__text mb-1 mb-md-0">
                    {{ t[locale]['header.your_balance'] }}
                  </div>

                  <div class="user-balance__wrap">
                    <div class="user-balance__amount">
                      {{ balance }}
                    </div>
                    <div class="bonuses-expire" v-if="userData.bonuses_will_expire > 0">
                      <vl-tooltip :isCloseBtn="true">
                        <template #activator="on">
                          <div v-on="on" class="d-flex">
                            <vl-icon-base width="16" height="16" viewBoxWidth="16" viewBoxHeight="16" opacity="1">
                              <vl-icon-exclamation-mark />
                            </vl-icon-base>
                          </div>
                        </template>
                        <template>
                          <div>
                            {{ t[locale]['profile.my_balance.bonuses_can_expire'].replace('{bonuses}', userData.bonuses_will_expire) }}
                            <a :href="rulesPage" class="link_rules" target="_blank">{{ t[locale]['profile.my_balance.rules'] }}</a>
                          </div>
                        </template>
                      </vl-tooltip>
                    </div>

                  </div>
                </div>
                <cart
                  v-if="!isOrderPage"
                  :locale="locale"
                  :default-locale="defaultLocale"
                  :user-id="this.userData['id']"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import authenticityToken from '@javascript/services/authenticityTokenService'
import Popap from './popap'
import LanguageContainer from './language-container'
import HeaderNavigation from './header-navigation'
import LoginForm from './login-form'
import Modal from './modal'
import VlButton from '@components/@oschad/ui/vl-button'
import VlIconBase from '@components/@oschad/ui/vl-icons'
import VlTooltip from '@components/@oschad/ui/vl-tooltip'
import VlIconAvatar from '@components/@oschad/ui/vl-icons/VlIconAvatar'
import VlIconBasket from '@components/@oschad/ui/vl-icons/VlIconBasket'
import Cart from './cart/Cart'
import locales from './locales'
import { isPageByNameRoute } from '@javascript/utils/helper-methods'
import { baseUrlWithLocale } from '@javascript/config/configuration'
import VlIconExclamationMark from '@components/@oschad/ui/vl-icons/VlIconeExclamationMark'
import {
  AUTHORIZATION_LOGIN,
  ORDER_SHOW,
  CATALOG,
  MAIN,
  VERIFY_EMAIL,
  AUTHORIZATION_LOGOUT,
  STATUS_REGISTRATION,
  FINISH_REGISTRATION,
} from '@javascript/config/routes'
import { mapActions } from 'vuex'

export default {
  name: 'Header',
  components: {
    Popap,
    LanguageContainer,
    HeaderNavigation,
    VlButton,
    LoginForm,
    Modal,
    Cart,
    VlIconBase,
    VlIconAvatar,
    VlIconBasket,
    VlTooltip,
    VlIconExclamationMark,
  },
  props: {
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
    availableLocales: {
      type: String,
      default: '',
    },
    currentUser: {
      type: String,
      default: '',
    },
    session: {
      type: String,
      default: null,
    },
    headerMenu: {
      type: String,
      default: '',
    },
    profileMenu: {
      type: String,
      default: '',
    },
    currentPath: {
      type: String,
      default: '',
    },
    providerId: {
      type: [String, Number],
      default: null,
    },
    webSiteName: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    t: locales,
    isShowLoginForm: false,
    isShowLoginBar: false,
    isShowMobileMenu: false,
    isShowMenu: false,
    userData: null,
    headerMenuData: null,
    profileMenuData: null,
    token: null,
  }),
  computed: {
    isRegistrationPages() {
      let urls = [STATUS_REGISTRATION, FINISH_REGISTRATION]
      return urls.some((url) => {
        return window.location.pathname.includes(url)
      })
    },
    isLogin() {
      // return JSON.parse(this.session)
      return JSON.parse(this.session) && !this.isRegistrationPages
    },
    balance() {
      return this.userData ? `${this.userData.balance} ${this.userData.site_currency}` : ''
    },
    // getBonusesWillExpireStr() {
    //   if (this.userData?.bonuses_will_expire !== undefined && this.userData?.bonuses_will_expire > 0) {
    //     return this.t[this.locale]['profile.my_balance.bonuses_will_expire'] + ' ' + this.userData?.bonuses_will_expire
    //   }
    // },
    userName() {
      return this.userData ? this.userData.first_name : ''
    },
    sortedHeaderMenu() {
      return this.headerMenuData.children.sort((prev, next) => {
        return prev.position - next.position
      })
    },
    sortedProfileMenu() {
      return this.profileMenuData.children.sort((prev, next) => {
        return prev.position - next.position
      })
    },
    loginRouter() {
      if (this.locale == this.defaultLocale) {
        return AUTHORIZATION_LOGIN
      }
      return `/${this.locale}${AUTHORIZATION_LOGIN}`
    },
    orderRouter() {
      if (this.locale == this.defaultLocale) {
        return ORDER_SHOW
      }
      return `/${this.locale}${ORDER_SHOW}`
    },
    isLoginPage() {
      return window.location.pathname === this.loginRouter
    },
    isOrderPage() {
      // return window.location.pathname == this.orderRouter
      const orderRouter = `${baseUrlWithLocale}${ORDER_SHOW}`
      return window.location.pathname == orderRouter
    },
    isVerifyEmailPage() {
      const path = window.location.pathname
      const regex = new RegExp('/(registration/verify_welcome)/')

      return path.match(regex)
    },
    isHideOnThisPage() {
      return !this.isOrderPage && !this.isVerifyEmailPage
    },
    authorizationLoginRouter() {
      if (this.locale == this.defaultLocale) {
        return AUTHORIZATION_LOGOUT
      }
      return `/${this.locale}${AUTHORIZATION_LOGOUT}`
    },
    rulesPage() {
      return `${baseUrlWithLocale}/topic/rules`
    },
  },
  methods: {
    ...mapActions({
      API_LOGOUT: 'authorization/API_LOGOUT',
    }),
    toggleMenu(value) {
      this.isShowMenu = value
    },
    toggleLoginBar() {
      this.isShowLoginBar = !this.isShowLoginBar
    },
    toggleLoginForm() {
      // console.log(this.isShowLoginForm)
      // if(!this.isShowLoginForm) {
      //   document.body.classList.add('overlay')
      // }else {
      //   document.body.classList.remove('overlay')
      // }
      this.isShowLoginForm = !this.isShowLoginForm
    },
    toggleMobileMenu() {
      this.isShowMobileMenu = !this.isShowMobileMenu
    },
    closeModal(modalName) {
      this[modalName] = false
    },
    catalogRouter() {
      if (this.locale == this.defaultLocale) {
        return CATALOG
      }
      return `/${this.locale}${CATALOG}`
    },
    homeRouter() {
      if (this.webSiteName === 'Oschad') return 'https://www.oschadbank.ua/'
      if (this.locale == this.defaultLocale) {
        return MAIN
      }
      return `/${this.locale}${MAIN}`
    },
    openLink(routeData) {
      let isCatalogPage = isPageByNameRoute(CATALOG)
      let isProductPage = isPageByNameRoute('product')
      if ((isCatalogPage && !routeData.do_redirect) && !isProductPage) {
        this.$bus.$emit('loadCategory', routeData)
        this.toggleMenu(false)
      } else {
        window.location.href = routeData.url
      }
    },
  },
  created() {
    this.userData = JSON.parse(this.currentUser)
    this.headerMenuData = JSON.parse(this.headerMenu)
    this.profileMenuData = JSON.parse(this.profileMenu)
    this.token = authenticityToken()
  },

  watch: {
    isShowLoginForm(oldValue, newValue) {
      if (oldValue) {
        document.body.classList.add('overlay')
      } else {
        document.body.classList.remove('overlay')
      }
    },
  },
}
</script>
