import { mapMutations } from 'vuex'
import savingErrorsToCore from '../utils/save-errors'

let getValidErrorObject = errors => {
  let errorsObject = {}
  for (let variable in errors) {
    let valueObjectProperty = errors[variable],
      isArray = Array.isArray(valueObjectProperty)
    if (isArray) {
      errorsObject[variable] = valueObjectProperty
    } else {
      errorsObject = { ...errorsObject, ...valueObjectProperty }
    }
  }
  return errorsObject
}

export default {
  methods: {
    ...mapMutations({
      // successNotification: 'notification/successNotification',
      errorNotification: 'notification/errorNotification',
      errorStaticNotification: 'notification/errorStaticNotification',
      clearStaticMessage: 'notification/clearStaticMessage',
    }),
    hideErrorNotification() {
      this.clearStaticMessage()
    },
    showErrorFormAndGlobal(error, formRefName, staticNotification) {
      this.showFormErrors(error, formRefName)
      this.showGlobalErrors(error, staticNotification)
    },
    showFormErrors(error, formRefName) {
      let errors = error.response.data.errors,
        validErrorsObject = getValidErrorObject(errors)
      this.$refs[formRefName].setErrors(validErrorsObject)
    },

    showGlobalErrors(error, staticNotification) {
      let errors = error.response.data.errors
        ? error.response.data.errors.global
        : error.response.data.error

      let globalError = Array.isArray(errors) ? errors[0] : errors

      if (globalError) {
        if (staticNotification) {
          this.errorStaticNotification({
            description: globalError,
            permanent: true,
          })
        } else {
          this.errorNotification({
            description: globalError,
          })
        }
      }
    },

    showErrors(error, staticNotification) {
      if (staticNotification) {
        this.errorStaticNotification({
          description: error,
          permanent: true,
        })
      } else {
        this.errorNotification({
          description: error,
        })
      }
    },

    showAllErrors(error, permanent, timeout, staticNotification) {
      let arrayErrors = this.concatAllErrors(error)
      if (!arrayErrors) {
        return
      }
      if (staticNotification) {
        this.errorStaticNotification({
          description: arrayErrors[0],
          permanent: permanent,
          timeout: timeout,
        })
      } else {
        arrayErrors.forEach(errorItem => {
          this.errorNotification({
            description: errorItem,
            permanent: permanent,
            timeout: timeout,
          })
        })
      }
      savingErrorsToCore(error)
    },
    getVisaError(error) {
      return error.response.data.errors ? error.response.data.errors.visa : null
    },
    concatAllErrors(errors) {
      let errorsData = errors.response.data.errors || errors.response.data.error
      let errorsArray = []
      if (Array.isArray(errorsData)) {
        errorsArray = errorsData
      } else if (errorsData instanceof Object && errorsData.constructor === Object) {
        for (let item in errorsData) {
          if (Array.isArray(errorsData[item])) {
            errorsArray = [...errorsArray, ...errorsData[item]]
          } else if (typeof item == 'string') {
            errorsArray = [...errorsArray, item]
          }
        }
      } else {
        errorsArray = [errorsData]
      }
      return errorsArray.length ? errorsArray : null
    },
    checkNeedTaxInfoError(error) {
      let errors = error.response.data.errors || error.response.data.error
      return errors && !!errors.need_tax_info
    },
  },
}
