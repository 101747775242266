export const MAIN = '/'
export const AUTHORIZATION_LOGIN = '/authorization/login'
export const AUTHORIZATION_LOGOUT = '/authorization/logout'
export const RESTORATION_RESETOPTION = '/restoration/reset_options'
export const REGISTRATION_CARD = '/registration/card'
export const REGISTRATION_DETAILS = '/registration/details'
export const PROFILE_MY_CARD = '/profile/my_cards'
export const PROFILE_EDIT = '/profile/edit'
export const PROFILE_INFO = '/profile/info'
export const ORDER_SUCCESS = '/order/success'
export const ORDER_SHOW = '/order/show'
export const PROFILE_ORDER = '/profile/my_orders'
export const CATALOG = '/catalog'
export const CASHBACK = '/easypay/cashback '
export const VERIFY_EMAIL = '/verify_email'
export const PRODUCT_ADDRESS = '/catalog/product_address'
export const PRIVACY_POLICY = '/topic/privacy_policy'
export const PROGRAM_RULES = '/topic/rules'
export const STATUS_REGISTRATION = '/registration/preloader_page'
export const FINISH_REGISTRATION = '/registration/finish_registration'
export const VERIFY_WELCOM = '/registration/verify_welcome'
export const REGISTRATION_FAILURE = '/registration/fatpay_failure'
export const PROFILE_MY_BALANCE = '/profile/my_balance'
export const AGENCY_FEE = '/profile/agency_fee'
export const EASYPAY_GATEWAY_SUCCESS = '/easypay_gateway/success'
