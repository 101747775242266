<template>
  <div v-if="showNotifications" class="vl-notification-static">
    <vl-notification-message
      :notification-data="notification"
      :title-translate="titleTranslate"
      @closeMessage="closeMessage"
    />
  </div>
</template>
<script>
import VlNotificationMessage from './components/vl-notification-message/VlNotificationMessage'
import { mapMutations, mapGetters } from 'vuex'
import locales from './locales'
export default {
  name: 'VlNotification',
  components: {
    VlNotificationMessage,
  },
  inject: ['locale'],
  props: {
    propNotification: null,
  },
  data() {
    return {
      t: locales,
      titleTranslate: '',
    }
  },
  methods: {
    ...mapMutations({
      clearStaticMessage: 'notification/clearStaticMessage',
      setStaticNotification: 'notification/setStaticNotification',
    }),
    closeMessage() {
      this.clearStaticMessage()
    },
    setNotification(notification) {
      if (notification && notification.description) {
        this.setStaticNotification(notification)
      }
    },
  },
  computed: {
    ...mapGetters({
      notification: 'notification/staticNotification',
    }),
    showNotifications() {
      return !!this.notification
    },
  },
  created() {
    this.setNotification(this.propNotification)
  },
  mounted() {
    this.$nextTick(() => {
      this.titleTranslate = this.t[locale]['notifications.error_title']
    })
  },
}
</script>
