import axios from './axios'
import baseUrl from './baseUrl'

const interfaceLogin = data => {
  return {
    user: {
      email: data.email,
      password: data.password,
      provider_id: data.providerId,
    },
  }
}

export function authorizationLogin(data) {
  const dataApi = interfaceLogin(data)
  return axios.post(`${baseUrl}/authorization/login.json`, dataApi)
}

export function authorizationLogout() {
  return axios.delete(`${baseUrl}/authorization/logout.json`)
}

export default {
  authorizationLogin,
  authorizationLogout,
}
