<template>
  <div class="vl-information-static-block">
    <p class="text">{{ text }}</p>
  </div>
</template>
<script>
export default {
  name: 'VlInformationStaticBlock',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
}
</script>
