<script>
import AppHeader from '@components/@oschad/general/header'
import PageHeader from '@components/@oschad/page-header'
import ProductSlider from '@components/product-slider'
import NewsSlider from '@components/news-slider'
import AppFooter from '@components/@oschad/general/footer'
import NotificationsList from '@components/@oschad/ui/vl-notification'
import VlAlert from '@components/@oschad/ui/vl-alert'
import setValidateMessages from '@javascript/plugins/localization/veeValidateMessage'

export default {
  name: 'App',
  provide: {
    locale: 'uk',
    defaultLocale: 'uk',
    baseUrlWithLocale: '',
    isGuest: true,
  },
  components: {
    AppHeader,
    AppFooter,
    NotificationsList,
    VlAlert,
    ProductSlider,
    PageHeader,
    NewsSlider,
    PersonalProfile: () => import('@javascript/pages/profile/personal-profile/oschad/PersonalProfile'),
    PersonalProfileEdit: () => import('@javascript/pages/profile/personal-profile-edit/oschad/PersonalProfileEdit'),
    PersonalMyBalance: () => import('@javascript/pages/profile/personal-my-balance/oschad/PersonalMyBalance'),
    RegistrationDetails: () => import('@javascript/pages/registration/details/oschad/RegistrationDetails'),
    FinishRegistrationDetails: () => import('@javascript/pages/registration/details/oschad/FinishRegistrationDetails'),
    PersonalCards: () => import('@javascript/pages/profile/personal-cards/oschad/PersonalCards'),
    PersonalAddCard: () => import('@javascript/pages/profile/personal-add-card/oschad/PersonalAddCard'),
    PersonalOrders: () => import('@javascript/pages/profile/personal-my-orders/oschad/PersonalMyOrders'),
    AuthorizationLogin: () => import('@javascript/pages/authorization/login/oschad/Login'),
    PersonalChangePassword: () =>
      import('@javascript/pages/profile/personal-change-password/oschad/PersonalChangePassword'),
    PersonalMyWishList: () => import('@javascript/pages/profile/personal-my-wish-list/oschad/PersonalMyWishList'),
    PersonalTaxEdit: () => import('@javascript/pages/profile/personal-tax-edit/oschad/PersonalTaxEdit'),
    AgencyFee: () => import('@javascript/pages/profile/agency-fee/oschad/AgencyFee'),
    ForgotPasswordOption: () =>
      import('@javascript/pages/restoration/forgot-password/reset-options/oschad/ForgotPasswordOptions'),
    VerificationCode: () =>
      import('@javascript/pages/restoration/forgot-password/verification-code/oschad/VerificationCode'),
    CreateNewPassword: () => import('@javascript/pages/restoration/forgot-password/new-password/oschad/NewPassword'),
    EmailVerifying: () => import('@javascript/pages/layouts/oschad/oschad-email-verifying/EmailVerifying'),
    OrderPage: () => import('@javascript/pages/order/show/oschad/Order'),
    OrderSuccess: () => import('@javascript/pages/order/success/oschad/OrderSuccess'),
    CatalogPage: () => import('@javascript/pages/catalog/oschad/Catalog'),
    ProductPage: () => import('@javascript/pages/product/oschad/ProductPage'),
    ProductAddress: () => import('@javascript/pages/product-address/oschad/ProductAddress'),
    RefillMobile: () => import('@javascript/pages/portmone/refill-mobile/oschad/RefillMobile'),
    PersonalChangeEmail: () => import('@javascript/pages/profile/personal-change-email/oschad/PersonalChangeEmail'),
    SessionPopup: () => import('@javascript/pages/layouts/oschad/session-popup/SessionPopup'),
    CashBack: () => import('@javascript/pages/cashback/oschad/CashBack'),
    CashBackShow: () => import('@javascript/pages/cashback/oschad/CashBackShow'),
    EasypayGateway: () => import('@javascript/pages/easypay-gateway/oschad/EasypayGateway'),
    EasypayGatewayShow: () => import('@javascript/pages/easypay-gateway/oschad/EasypayGatewayShow'),
    EasypayGatewaySuccess: () => import('@javascript/pages/easypay-gateway/oschad/EasypayGatewaySuccess'),
    ConvertBonuses: () => import('@javascript/pages/convertbonuses/oschad/ConvertBonuses'),
    Ding: () => import('@javascript/pages/ding/oschad'),
    VlPopup: () => import('@javascript/pages/profile/personal-profile/popup'),
    PagePreloader: () => import('@components/@oschad/page-preloader'),
  },
  methods: {
    setLocalesSeting() {
      this._provided.locale = this.$el.attributes.locale.value
      this._provided.defaultLocale = this.$el.attributes.defaultLocale.value
      this._provided.baseUrlWithLocale = this.$el.attributes.base_url_with_locale.value
      this._provided.isGuest = !JSON.parse(this.$el.attributes.is_guest.value)
      setValidateMessages(this._provided.locale)
    },
  },
  mounted() {
    this.setLocalesSeting()
  },
}
</script>
