var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'vl-btn',
    _vm.type ? 'vl-btn--type-' + _vm.type : 'vl-btn--type-primary',
    _vm.size ? 'vl-btn--size-' + _vm.size : 'vl-btn--size-large',
    _vm.align ? 'vl-btn--align-' + _vm.align : 'd-flex justify-content-center align-items-center',
    _vm.textTransform ? 'vl-btn--text-transform-' + _vm.textTransform : '',
    {
      'vl-btn--disabled': _vm.disabledButton,
      'vl-btn--loading': _vm.loading,
    },
  ],style:({
    maxWidth: _vm.maxWidth + 'px',
    maxHeight: _vm.maxHeight + 'px',
    minWidth: _vm.minWidth + 'px',
    fontSize: _vm.fontSize + 'px',
  }),attrs:{"disabled":_vm.disabledButton,"type":_vm.actionType}},[(_vm.loading)?[_c('div',{staticClass:"vl-btn__loading-icon"},[_c('vl-icon-base',{attrs:{"icon-name":"loader","width":"24","height":"24"}},[_c('vl-icon-loader')],1)],1)]:_vm._e(),_vm._v(" "),[_vm._t("default")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }