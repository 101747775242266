<template>
  <div
    :class="[
      'vl-input-group',
      {
        'vl-input-group--active': isActive,
        'vl-input-group--has-error': error,
        'vl-input-group--on-color-bg': onColorBg,
      },
    ]"
  >
    <fieldset
      :class="[
        'vl-input-group__title',
        {
          'vl-input-group__title--active': isActive,
          'vl-input-group__title--has-error': error,
        },
      ]"
      aria-hidden="true"
    >
      <legend ref="legend">
        <span>&#160;</span>
      </legend>
    </fieldset>
    <div class="vl-input-group__input-slot">
      <input
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :name="name"
        :autocomplete="autocomplete"
        :class="[{ 'vl-input-group__vl-input-password': type === 'password' && showEye }, 'vl-input-group__vl-input-text']"
        :type="inputType"
        ref="input"
        @input="$emit('input', $event.target.value)"
        @change="onChangeValue"
        @blur="handleBlur"
        @focus="handleFocus"
        @keypress="keypress"
      />
      <label
        :class="[
          'vl-input-group__vl-label',
          {
            'vl-input-group__vl-label--active': isActive,
            'vl-input-group__vl-label--has-error': error,
          },
        ]"
      >
        <span ref="label">{{ label }}</span>
      </label>
      <div v-if="$slots.icon && type !== 'password'" class="icon">
        <slot name="icon" />
      </div>
      <div v-else-if="type === 'password' && showEye" class="icon-eye" @click.stop="toggleShowPassword">
        <vl-icon-eye-slash  v-if="showPassword"/> <vl-icon-eye v-else />
      </div>
    </div>
  </div>
</template>
<script>
import fieldset from '@javascript/mixins/fieldset'
import VlIconEye from '@components/@oschad/ui/vl-icons/VlIconEye'
import VlIconEyeSlash from '@components/@oschad/ui/vl-icons/VlIconEyeSlash'
export default {
  name: 'VlInputText',
  components: {
    VlIconEye,
    VlIconEyeSlash,
  },
  mixins: [fieldset],
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator: (val) => ['text', 'tel', 'email', 'password', 'hidden', 'number'].includes(val),
    },
    label: {
      type: String,
      default: 'Input text',
    },
    error: {
      type: Boolean,
      default: false,
    },
    onColorBg: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    showEye: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    hasFocus: false,
    showPassword: false,
  }),
  watch: {
    value(newValue) {
      if (!newValue && !this.hasFocus) {
        this.isActive = false
      } else if (newValue && !this.isActive && !this.hasFocus) {
        this.isActive = true
      }
    },
  },
  methods: {
    onChangeValue(evt) {
      this.$emit('change', evt.target.value)
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    handleBlur() {
      this.blur()
      this.hasFocus = false
    },
    handleFocus(evt) {
      this.focus()
      this.$emit('focus', evt)
      this.hasFocus = true
    },
    keypress(evt) {
      this.$emit('keypress', evt)
    },
  },
  computed: {
    inputType() {
      return this.showPassword ? 'text' : this.type
    }
  }
}
</script>
