<template>
  <div class="cart">
    <div class="cart-content">
      <form :action="orderRouter()" method="post">
        <input type="hidden" name="authenticity_token" :value="token" />
        <template v-if="cartProducts.length">
          <div class="cart-items mb-3">
            <transition-group name="fade">
              <div class="cart-item" v-for="product of cartProducts" :key="product.id">
                <div class="cart-item__header">
                  <div class="cart-item__title">{{ product.title }}</div>
                  <vl-button
                    class="cart-item__remove-action d-flex"
                    type="inline"
                    size="inline"
                    @click.native="removeProductFromCart(product)"
                  >
                    <vl-icon-base
                      width="16"
                      height="17"
                      viewBoxWidth="16"
                      viewBoxHeight="17"
                      iconColor="white"
                      opacity="0.5"
                    >
                      <vl-icon-remove />
                    </vl-icon-base>
                  </vl-button>
                </div>
                <div class="cart-item__active-block">
                  <div class="cart-item__calc w-100">
                    <vl-button
                      class="cart__calc-button"
                      type="inline"
                      size="inline"
                      max-width="32"
                      max-height="32"
                      :disabled="product.quantity <= minimumOrderQuantity"
                      @click.native="decrementItemQuantity(product)"
                    >
                      <vl-icon-base iconColor="transparent" width="32" height="32" viewBoxWidth="32" viewBoxHeight="32">
                        <vl-icon-minus />
                      </vl-icon-base>
                    </vl-button>
                    <input type="text" class="cart-item__calc-input" readonly :value="product.quantity" />
                    <vl-button
                      class="cart__calc-button"
                      type="inline"
                      size="inline"
                      max-width="32"
                      max-height="32"
                      :disabled="product.quantity >= product.stockCount"
                      @click.native="addProductToCart(product)"
                    >
                      <vl-icon-base iconColor="transparent" width="32" height="32" viewBoxWidth="32" viewBoxHeight="32">
                        <vl-icon-plus />
                      </vl-icon-base>
                    </vl-button>
                    <div class="cart-item__cost ml-auto">
                      {{ product.sitePrice }}
                      <span>{{ product.sitePrice | serializeBonus }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </transition-group>
            <div class="d-md-none d-block mt-4">
              <div class="cart-total mb-4">
                <div class="cart-total__text">{{ t[locale]['cart.total'] }}</div>
                <div class="cart-total__amount">{{ cartTotalPrice }} {{ cartTotalPrice | serializeBonus }}</div>
              </div>

              <div v-for="product of cartProducts" :key="product.id">
                <input type="hidden" name="items[][id]" v-model="product.id" />
                <input type="hidden" name="items[][quantity]" v-model="product.quantity" />
              </div>
              <div class="d-flex justify-content-center">
                <vl-button
                  class="vl-btn vl-btn--type-primary vl-btn--size-small submit-btn"
                  type="submit"
                  actionType="submit"
                >
                  {{ t[locale]['cart.order'] }}
                </vl-button>
              </div>
            </div>
          </div>
          <div class="d-md-block d-none">
            <div class="cart-total mb-4">
              <div class="cart-total__text">{{ t[locale]['cart.total'] }}</div>
              <div class="cart-total__amount">{{ cartTotalPrice }} {{ cartTotalPrice | serializeBonus }}</div>
            </div>

            <div v-for="product of cartProducts" :key="product.id">
              <input type="hidden" name="items[][id]" v-model="product.id" />
              <input type="hidden" name="items[][quantity]" v-model="product.quantity" />
            </div>
            <div class="d-flex justify-content-center">
              <vl-button
                class="vl-btn vl-btn--type-primary vl-btn--size-small submit-btn"
                type="submit"
                actionType="submit"
                :loading="isLoading"
              >
                {{ t[locale]['cart.order'] }}
              </vl-button>
            </div>
          </div>
        </template>
        <template v-else>
          <span class="cart__info-text">
            {{ t[locale]['cart.cart_empty'] }}
          </span>
        </template>
      </form>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import VlButton from '@components/@oschad/ui/vl-button'
import VlIconBase from '@components/@oschad/ui/vl-icons'
import VlIconRemove from '@components/@oschad/ui/vl-icons/VlIconRemove'
import VlIconPlus from '@components/@oschad/ui/vl-icons/VlIconPlus'
import VlIconMinus from '@components/@oschad/ui/vl-icons/VlIconMinus'

import locales from './locales'
import authenticityToken from '@javascript/services/authenticityTokenService'

const ORDER_PAGE_ROUTER = '/order/show'
const PRODUCTS_KEY = 'products_u_'

export default {
  name: 'Cart',
  components: {
    VlButton,
    VlIconBase,
    VlIconRemove,
    VlIconPlus,
    VlIconMinus,
  },
  props: {
    userId: {
      type: [String, Number],
    },
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    t: locales,
    minimumOrderQuantity: 1,
    token: authenticityToken(),
  }),
  computed: {
    ...mapGetters({
      cartProducts: 'cart/cartProducts',
      cartTotalPrice: 'cart/cartTotalPrice',
      isLoading: 'cart/isLoading',
    }),
  },
  methods: {
    ...mapActions({
      addProductToCart: 'cart/addProductToCart',
      removeProductFromCart: 'cart/removeProductFromCart',
      API_recalculate: 'cart/API_recalculate',
    }),
    ...mapMutations({
      decrementItemQuantity: 'cart/decrementItemQuantity',
      initialiseStore: 'cart/initialiseStore',
      setUserId: 'cart/setUserId',
    }),
    orderRouter() {
      if (this.locale == this.defaultLocale) {
        return ORDER_PAGE_ROUTER
      }
      return `/${this.locale}${ORDER_PAGE_ROUTER}`
    },
    getItemsFromLocalstorage(storageKey) {
      const items = JSON.parse(window.localStorage.getItem(storageKey))
      return items
    },
    setItemsToLocalstorage(state) {
      window.localStorage.setItem(`${PRODUCTS_KEY}${this.userId}`, JSON.stringify(state.cart.items))
    },
    initStore(store) {
      const products = JSON.parse(window.localStorage.getItem(`${PRODUCTS_KEY}${this.userId}`))
      this.initialiseStore(products)
    },
    updateStore(data) {
      if (data.key === `${PRODUCTS_KEY}${this.userId}`) {
        const product = JSON.parse(data.newValue)
        this.initialiseStore(product)
      }
      return false
    },
    subscribeVuex() {
      this.$store.subscribe((mutation, state) => {
        this.setItemsToLocalstorage(state)
      })
    },
  },
  created() {
    const data = this.getItemsFromLocalstorage(`${PRODUCTS_KEY}${this.userId}`)
    this.initStore(data)

    this.setUserId(this.userId)
    this.subscribeVuex()

    window.addEventListener('storage', (data) => {
      this.updateStore(data)
    })
  },
  async mounted() {
    await this.API_recalculate()
  },
}
</script>
